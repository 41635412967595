import React, { useState, useEffect, useRef } from 'react'
import { useCookies } from 'react-cookie'
import useSound from 'use-sound'

/**
 * Audio track that uses cookies to remember
 * if audio should play
 */
function AudioTrack({ src }) {
  const audioRef = useRef(0)
  const [soundPlaying, setSoundPlaying] = useState(true)
  const [cookies, setCookies] = useCookies(['nftSiteSettings'])
  const [play, { stop }] = useSound(src, { volume: 0.7, interrupt: true }) // 70% of the original volume

  useEffect(() => {
    if (cookies.playSound) {
      if (cookies.playSound == 'true') {
        setSoundPlaying(true)
      } else {
        setSoundPlaying(false)
      }
    }
  }, [])

  function toggleSound() {
    if (soundPlaying) {
      setCookies('playSound', false, { path: '/' })
      stop()
    } else {
      setCookies('playSound', true, { path: '/' })
      play()
    }
    setSoundPlaying(!soundPlaying)
  }

  if (soundPlaying) play()
  else stop()
  return (
    <img
      style={{
        position: 'absolute',
        top: 15,
        right: 15,
        width: 35,
        zIndex: 99,
        cursor: 'pointer',
      }}
      onClick={toggleSound}
      src={soundPlaying ? '/assets/volume-up.png' : '/assets/no-sound.png'}
    />
  )
}

export default React.memo(AudioTrack)
