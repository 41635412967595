import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  walletAddressShortSelector,
  cryptoFunctionsAtom,
  contractStateAtom,
  walletAddressAtom,
  userTermsAtoms,
  availableOathAtom,
  claimedOathAtom,
} from '../../recoil/atoms'
import axios from 'axios'
import { Navbar, Nav } from 'react-bootstrap'

const cgSimpleApiURL = 'https://api.coingecko.com/api/v3/simple/'

const getOathPrice = async () => {
  const axGet = await axios
    .get(cgSimpleApiURL + `price?ids=oath&vs_currencies=usd`)
    .then((res) => {
      return res.data['oath'].usd
    })
    .catch((e) => {
      console.error('getOathPrice', e)
    })
  return axGet
}

const getFantomPrice = async () => {
  const axGet = await axios
    .get(cgSimpleApiURL + `price?ids=fantom&vs_currencies=usd`)
    .then((res) => {
      return res.data['fantom'].usd
    })
    .catch((e) => {
      console.error('getFantomPrice', e)
    })
  return axGet
}

export default function SquareCornerNav() {
  const walletAddressShort = useRecoilValue(walletAddressShortSelector)
  const [walletAddress] = useRecoilState(walletAddressAtom)

  const [oathPrice, setOathPrice] = useState(0)
  const [ftmPrice, setFtmPrice] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      getOathPrice().then((res) => {
        if (res != oathPrice) setOathPrice(res)
      })
    }, 15000)
    getOathPrice().then((res) => setOathPrice(res))

    setTimeout(() => {
      getFantomPrice().then((res) => {
        if (res != ftmPrice) setFtmPrice(res)
      })
    }, 15000)
    getFantomPrice().then((res) => setFtmPrice(res))
  }, [])
  return (
    <>
      <div
        className="nav"
        style={{
          position: 'absolute',
          left: '0px',
          top: '0px',
          zIndex: 10,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100px',
              height: '55px',
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <h5>Menu</h5>
          </div>
          <div style={{ margin: '10px', lineHeight: '12px' }}>
            <p>
              {' '}
              <Nav.Link
                style={{ color: 'white', width: '150px' }}
                href="https://www.coingecko.com/en/coins/oath"
                target="_blank"
              >
                <img height="30" src="/media/OATHMiniLogo.png"></img> $
                {oathPrice.toFixed(2)}
              </Nav.Link>
              <Nav.Link
                style={{ color: 'white', width: '150px', cursor: 'pointer' }}
                href="https://www.coingecko.com/en/coins/fantom"
                target="_blank"
              >
                <img height="30" src="/media/fantomLogoTrans.png"></img> $
                {ftmPrice.toFixed(2)}
              </Nav.Link>
            </p>
          </div>
        </div>

        <div style={{ margin: '5px' }}>
          <Nav.Link style={{ color: 'white', width: '150px' }} href="mint">
            Mint
          </Nav.Link>
          <Nav.Link style={{ color: 'white', width: '150px' }} href="quiz">
            Quiz <font style={{ color: 'gold', fontSize: 10 }}>Airdrop!</font>
          </Nav.Link>
          <Nav.Link style={{ color: 'white', width: '150px' }} href="gallery">
            Gallery
          </Nav.Link>
          <Nav.Link style={{ color: 'white', width: '150px' }} href="lore">
            Lore
          </Nav.Link>
          <Nav.Link style={{ color: 'white', width: '150px' }} href="partners">
            Partners
          </Nav.Link>

          <Nav.Link
            style={{ color: 'white', width: '150px' }}
            href={`https://testnet.ftmscan.com/address/${walletAddress}`}
          >
            {walletAddressShort}
          </Nav.Link>
        </div>
      </div>
    </>
  )
}
