import React, { useRef, useState, useEffect } from 'react'
import SquareCornerNav from '../../components/SquareCornerNav/SquareCornerNav'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'

import AudioTrack from '../../components/AudioTrack/AudioTrack'
import { Animated } from 'react-animated-css'
import { useCookies } from 'react-cookie'
import { useRecoilState } from 'recoil'
import {
  mintingAtom,
  mintedTokenAtom,
  nftCardClassAtom,
} from '../../recoil/atoms'
import './BaseView.css'
import NFTFlipCard from '../../components/NFTFlipCard/NFTFlipCard'
import { clowns, sounds } from '../../general/variables'
import useSound from 'use-sound'

var counter = 0
var inter = 0

function NFTFlipCardMinting() {
  const [minting, setMinting] = useRecoilState(mintingAtom)
  const [clownIndex, setClownIndex] = useState(0)
  const [mintedTokens, setMintedToken] = useRecoilState(mintedTokenAtom)
  const [divClass, setDivClass] = useRecoilState(nftCardClassAtom)
  const [playDrumRoll, { stop }] = useSound(sounds.drumRoll, {
    volume: 0.7,
    interrupt: true,
  }) // 70% of the original volume

  useEffect(() => {
    if (inter != 0) clearInterval(inter)
    inter = setInterval(() => {
      counter += 1
      setClownIndex(counter % clowns.length)
    }, 100)
  }, [])

  useEffect(() => {
    if (mintedTokens && mintedTokens.length > 0) playDrumRoll()
  }, [mintedTokens])

  let clown = clowns[clownIndex]
  if (minting && divClass !== 'nftcard__div__minting') {
    setDivClass('nftcard__div__minting')
  }
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflowY: 'visible',
      }}
    >
      <div className={divClass} style={{ overflowY: 'visible' }}>
        {/* {clowns.map((token) => (
          <NFTFlipCard
            animationDelay={0}
            logoSrc={'/CursedCircus.png'}
            tokenUri={token}
          />
        ))} */}
        <Container
          fluid
          style={{
            height: '100%',
            width: '100%',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'scroll',
            overflowY: 'visible',
          }}
        >
          {mintedTokens ? (
            mintedTokens.map((token, i) => (
              <Animated
                animationIn="fadeInUp"
                animationOut="fadeOut"
                animationInDelay={i * 200}
                isVisible={true}
              >
                <NFTFlipCard
                  animationDelay={0}
                  logoSrc={'/CursedCircus.png'}
                  tokenUri={token}
                  imageLoadDelay={i * 100}
                />
              </Animated>
            ))
          ) : (
            <NFTFlipCard
              animationDelay={0}
              logoSrc={'/CursedCircus.png'}
              tokenUri={clown}
            />
          )}
        </Container>
      </div>
    </div>
  )
}
function BackgroundOverlay() {
  const [curtainsOpen, setCurtainsOpen] = useState(false)
  const [minting, setMinting] = useRecoilState(mintingAtom)
  let curtainClass = 'background__image__curtains'
  console.log(curtainsOpen, minting)
  if (minting) {
    curtainClass += ' curtains_open'
  } else {
    curtainClass += ' curtains_close'
  }
  return (
    <>
      <img className="clownhead" src={'/assets/clownface.png'} />
      <img className="clownhead" src={'/assets/clownface.png'} />
      <NFTFlipCardMinting
        className="nftcard"
        style={{ overflowY: 'visible' }}
      />
      <img
        className="background__image__floor"
        src={'/assets/circusRingBg-floor.png'}
      />
      <img className={curtainClass} src={'/assets/circusRingBg-curtains.png'} />

      <img
        className="background__image"
        src={'/assets/circusRingBg-no-curtains.png'}
      />

      <div className="background__div" />
    </>
  )
}

function Logo({ showIntro }) {
  return (
    <img
      className={showIntro ? 'logo-animated' : 'logo'}
      style={{
        position: 'absolute',
        left: '50%',
        maxHeight: '150px',
        height: '10%',
        transform: 'translate(-50%,0)',
      }}
      src={'/CursedCircus.png'}
    />
  )
}

function BaseView({ children }) {
  const [minting, setMinting] = useRecoilState(mintingAtom)
  const [showIntro, setShowIntro] = useState(true)
  const [cookies, setCookies] = useCookies(['nftSiteSettings'])
  useEffect(() => {
    setTimeout(() => {
      setCookies('introPlayed', true, { path: '/', maxAge: 60 * 10 })
      setShowIntro(false)
    }, 5000)
  }, [])

  let delay = 5000
  if (cookies.introPlayed) {
    if (cookies.introPlayed == 'true') {
      delay = 0
      if (showIntro) setShowIntro(false)
    } else {
      if (!showIntro) setShowIntro(true)
      delay = 5000
    }
  }

  const getSpotLightClass = () => {
    console.log('showIntro', showIntro)
    console.log('minting', minting)
    if (minting) return 'spotlight spotlight__animated__mint'
    if (showIntro) return 'spotlight spotlight__animated'
    return 'spotlight'
  }
  let spotlightClass = getSpotLightClass()
  console.log(spotlightClass)
  return (
    <>
      <AudioTrack src={sounds.honkTheme} />
      <SquareCornerNav />
      <div className={spotlightClass}>
        <BackgroundOverlay />
        <Logo showIntro={showIntro} />
        <Animated
          className="baseview__animated__div"
          animationIn="fadeInUp"
          animationOut="fadeOut"
          animationInDelay={delay + 200}
          isVisible={true}
        >
          {children}
        </Animated>
      </div>
    </>
  )
}

export default React.memo(BaseView)
