import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import { RecoilRoot } from 'recoil'
import { positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import Crypto from './components/Crypto/Crypto'
import CheckNetwork from './components/CheckNetwork/CheckNetwork'
import BaseView from './views/BaseView/BaseView'
import MintView from './views/MintView/MintView'
import GalleryView from './views/GalleryView/GalleryView'
import LoreView from './views/LoreView/LoreView'
import PartnersView from './views/PartnersView/PartnersView'
import QuizView from './views/QuizView/QuizView'
import QuizCountDown from './views/QuizView/QuizCountDown'

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 10000,
  offset: '30px',
}



export default function App() {
  return (
    <RecoilRoot>
      <AlertProvider template={AlertTemplate} {...options}>
        <Crypto>
          <CheckNetwork>
            <BaseView>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<MintView />}>
                    <Route index element={<MintView />} />
                  </Route>
                  <Route path="/mint" element={<MintView />}></Route>
                  <Route path="/gallery" element={<GalleryView />}></Route>
                  <Route path="/lore" element={<LoreView />}></Route>
                  <Route path="/partners" element={<PartnersView />}></Route>
                  <Route path="/quiz" element={<QuizCountDown><QuizView /></QuizCountDown>}></Route>
                  {/* <Route path="/temptestquiz" element={<QuizView />}></Route> */}
                </Routes>
              </BrowserRouter>
            </BaseView>
          </CheckNetwork>
        </Crypto>
      </AlertProvider>
    </RecoilRoot>
  )
}
