import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'

import { useRecoilState } from 'recoil'
import nftAbiOld from '../../artifacts/src/contracts/SKULLY.sol/SKULLY.json'

import {
  walletAddressAtom,
  batchPriceAtom,
  statusAtom,
  cryptoFunctionsAtom,
  correctNetworkAtom,
  contractStateAtom,
  userTermsAtoms,
  availableOathAtom,
  claimedOathAtom,
  oathAllowedSpendAtom,
  ftmAllowedSpendAtom,
  contractPausedAtom,
  nftTotalSupplyAtom,
  nftMaxSupplyAtom,
  userTokenJsonsAtom,
  availableDiscountNftsAtom,
  mintingAtom,
  selectedDiscountNftAtom,
  mintedTokenAtom,
  nftCardClassAtom,
  allMintedTokenJsonsAtom,
} from '../../recoil/atoms'

import { ethers } from 'ethers'
import {
  lgeAddress as LGEAddress,
  oathAddress,
  nftAddress,
  ftmAddress,
  lgeAbi,
  wFtmAbi,
  nftDiscounts,
  nftAbi,
} from '../../general/variables'

const FANTOM_NETWORK_ID = '250'
const PAIN =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
const ZERO = '0x0000000000000000000000000000000000000000'
let LGE
let ERC20
var NFTContract
let signer
var provider
var interval = 0
function Crypto({ children }) {
  const alert = useAlert()
  const [, setBuyReceipt] = useState(null)
  const [userTerms, setUserTerms] = useRecoilState(userTermsAtoms)
  const [minting, setMinting] = useRecoilState(mintingAtom)

  const [setIsDev] = useState(false)
  const [,] = useRecoilState(batchPriceAtom)
  const [, setContractState] = useRecoilState(contractStateAtom)
  const [correctNetwork, setCorrectNetwork] = useRecoilState(correctNetworkAtom)
  const [walletAddress, setWalletAddress] = useRecoilState(walletAddressAtom)
  const [, setStatus] = useRecoilState(statusAtom)
  const [, setCryptoFunctions] = useRecoilState(cryptoFunctionsAtom)
  const [claimed, setClaimed] = useRecoilState(claimedOathAtom)
  const [, setAvailableOath] = useRecoilState(availableOathAtom)
  const [, setFtmAllowedSpend] = useRecoilState(ftmAllowedSpendAtom)
  const [, setOathAllowedSpend] = useRecoilState(oathAllowedSpendAtom)
  const [, setContractPaused] = useRecoilState(contractPausedAtom)
  const [totalSupply, setTotalSupply] = useRecoilState(nftTotalSupplyAtom)
  const [, setMaxSupply] = useRecoilState(nftMaxSupplyAtom)
  const [userTokenJsons, setUserTokenJsons] = useRecoilState(userTokenJsonsAtom)
  const [allMintedTokenJsons, setAllMintedTokenJsons] = useRecoilState(
    allMintedTokenJsonsAtom,
  )

  const [, setAvailableDiscountNfts] = useRecoilState(availableDiscountNftsAtom)
  const [isMinting, setIsMinting] = useRecoilState(mintingAtom)
  const [selectedDiscountNft, setSelectedDiscountNft] = useRecoilState(
    selectedDiscountNftAtom,
  )
  const [mintedToken, setMintedToken] = useRecoilState(mintedTokenAtom)
  const [divClass, setDivClass] = useRecoilState(nftCardClassAtom)

  const readContractState = async () => {
    await updateContractMaxSupply()
  }
  const readContractStateInterval = async () => {
    if (userTerms.term <= 0) await getUserLGETerms()
    await updateContractPaused()
    await updateContractTotalSupply()
  }

  useEffect(async () => {
    if (correctNetwork) {
      await getCurrentWalletConnected()
      await readContractState()
      await readContractStateInterval()
      interval = setInterval(readContractStateInterval, 5000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [correctNetwork])

  useEffect(async () => {
    await checkCorrectNetwork()
    await initializeEthers()
    setCryptoFunctions({
      connectWallet: connectWalletPressed,
      connectToFantomNetwork: connectToFantomNetwork,
      getUserTerms: getUserLGETerms,
      approveSpend: approveSpend,
      getAllowance: getAllowance,
      approveFtmSpend: approveFtmSpend,
      approveOathSpend: approveOathSpend,
      getAllMintedNFTs: getAllMintedNFTs,
      getUserNFTs: getUserNFTs,
      mint: mint,
    })
  }, [])

  useEffect(async () => {
    if (walletAddress) {
      getUserNFTDiscounts()
      getUserLGETerms()
      updateAllowedSpend()
    }
  }, [walletAddress])

  // useEffect(async () => {
  //   console.log('userTokenJsons', userTokenJsons)
  // }, [userTokenJsons])

  // useEffect(async () => {
  //   await getUserNFTs()
  // }, [totalSupply])

  async function initCrypto() {
    await getUserLGETerms()
    addWalletListener()
  }

  const connectToFantomNetwork = async () => {
    try {
      if (!window.ethereum) {
        alert(
          'No wallet found. Please ensure your browser has the Metamask extension installed or try using the Metamask mobile app browser.',
        )
        throw new Error('No crypto wallet found')
      }
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(FANTOM_NETWORK_ID).toString(16)}`,
            chainName: 'Fantom Opera',
            nativeCurrency: {
              name: 'Fantom',
              symbol: 'FTM',
              decimals: 18,
            },
            rpcUrls: ['https://rpc.ftm.tools/'],
            blockExplorerUrls: ['https://ftmscan.com/'],
          },
        ],
      })
      checkCorrectNetwork()
    } catch (err) {}
  }

  const getUserNFTDiscounts = async () => {
    let availableNfts = []
    console.log(
      'DEBUG:getUserNFTDiscounts::getting user nft discounts',
      walletAddress,
    )
    for (let i = 0; i < nftDiscounts.length; i++) {
      try {
        let contract = new ethers.Contract(
          nftDiscounts[i].address,
          nftAbi,
          provider.getSigner(0),
        )
        let nftBalance = await contract.balanceOf(walletAddress)
        nftBalance = nftBalance.toNumber()
        if (nftBalance > 0) {
          availableNfts.push(nftDiscounts[i])
        }
        console.log(
          'DEBUG:getUserNFTDiscounts::checking contract:',
          nftBalance,
          nftDiscounts[i].name,
          nftDiscounts[i].address,
        )
      } catch (err) {
        console.log('DEBUG:getUserNFTDiscounts::error getting nft', err)
      }
    }
    setAvailableDiscountNfts(availableNfts)
    // console.log('DEBUG:getUserNFTDiscounts::nft discounts: ', availableNfts)
  }

  const connectWalletPressed = async () => {
    initCrypto()
    await connectWallet()
  }

  const initializeEthers = async () => {
    if (!window.ethereum) {
      alert(
        'No wallet found. You will not be able to use this site without one. Please ensure your browser has the Metamask extension installed or try using the Metamask mobile app browser.',
      )
      throw new Error('No crypto wallet found')
    }

    provider = new ethers.providers.Web3Provider(window.ethereum)
    provider.on('network', (newNetwork, oldNetwork) => {
      if (oldNetwork) {
        window.location.reload()
        initializeEthers()
        initCrypto()
      }
    })

    try {
      // if (!correctNetwork) return
      signer = provider.getSigner(0)
      ERC20 = new ethers.Contract(oathAddress, wFtmAbi, provider.getSigner(0))
      NFTContract = new ethers.Contract(
        nftAddress,
        nftAbi,
        provider.getSigner(0),
      )
      LGE = new ethers.Contract(LGEAddress, lgeAbi, provider.getSigner(0))
    } catch (error) {
      console.log(error)
      return
    }
  }

  // Checks if wallet is connected to the correct network
  const checkCorrectNetwork = async () => {
    try {
      const { ethereum } = window
      let chainId = await ethereum.request({ method: 'eth_chainId' })
      const rinkebyChainId = '0x4'
      const fantomChainId = `0x${Number(FANTOM_NETWORK_ID).toString(16)}`
      const devChainId = 1337
      const localhostChainId = `0x${Number(devChainId).toString(16)}`
      // if (chainId == devChainId) {
      //   setIsDev(true)
      // }
      if (
        chainId !== rinkebyChainId &&
        chainId !== localhostChainId &&
        chainId !== fantomChainId
      ) {
        setCorrectNetwork(false)
      } else {
        await initializeEthers()
        setCorrectNetwork(true)
      }
    } catch (e) {
      console.log('checkCorrectNetwork error', e)
    }
  }

  const connectWallet = async () => {
    try {
      const addressArray = await provider.send('eth_requestAccounts', [])
      setWalletAddress(addressArray[0])
    } catch (err) {
      console.error('connectWallet error: ' + err.message)
    }
  }

  const updateAllowedSpend = async () => {
    setFtmAllowedSpend(
      await getAllowance(ftmAddress, nftAddress, walletAddress),
    )
    setOathAllowedSpend(
      await getAllowance(oathAddress, nftAddress, walletAddress),
    )
  }

  const approveFtmSpend = async (walletAddress) => {
    alert.show('Approving FTM Spend...')
    if (await approveSpend(ftmAddress)) {
      let allowance = await getAllowance(ftmAddress, nftAddress, walletAddress)
      setFtmAllowedSpend(allowance)
    } else setFtmAllowedSpend(0)
  }

  const approveOathSpend = async (walletAddress) => {
    alert.show('Approving OATH Spend...')

    if (await approveSpend(oathAddress)) {
      console.log('approveOathSpend', walletAddress)
      let allowance = await getAllowance(oathAddress, nftAddress, walletAddress)
      setOathAllowedSpend(allowance)
    } else setOathAllowedSpend(0)
  }

  const getAllowance = async (erc20Address, spender, wallet) => {
    try {
      let contract = await ERC20.attach(erc20Address)
      let allowedSpend = await contract.allowance(wallet, spender)
      return parseInt(allowedSpend)
    } catch (error) {
      console.error('getAllowance error: ' + error.message)
      return false
    }
  }

  const updateContractPaused = async () => {
    try {
      let publicPaused = await NFTContract.publicPaused()
      setContractPaused(publicPaused)
    } catch (error) {
      console.error('updateContractPaused error: ' + error.message)
      setContractPaused(true)
    }
  }

  const updateContractMaxSupply = async () => {
    try {
      let maxSupply = await NFTContract.maxSupply()
      setMaxSupply(maxSupply.toNumber())
    } catch (error) {
      console.error('updateContractMaxSupply error: ' + error.message)
      setMaxSupply(0)
    }
  }

  const updateContractTotalSupply = async () => {
    try {
      let totalSupply = await NFTContract.totalSupply()
      setTotalSupply(totalSupply.toNumber())
    } catch (error) {
      console.error('updateContractMaxSupply error: ' + error.message)
      setTotalSupply(0)
    }
  }
  var tokenJsons = []

  const getAllMintedNFTs = async () => {
    try {
      tokenJsons = []
      let promises = []
      let maxSupply = await NFTContract.maxSupply()
      let ids = tokenJsons.filter((token) => token.id)
      for (let tokenId = 0; tokenId < maxSupply; tokenId++) {
        if (ids.includes(tokenId)) continue
        promises.push(
          NFTContract.tokenURI(tokenId)
            .then((tokenUri) => fetch(tokenUri))
            .then((data) => data.json())
            .then((jsonData) => {
              tokenJsons.push(jsonData)
            })
            .catch((e) => {
              console.log('crypto::getAllMintedNFTs:: failed to get id', e)
            }),
        )
      }

      Promise.all(promises).then(() => {
        setAllMintedTokenJsons(tokenJsons)
      })
    } catch (error) {
      console.error('getUserNFTs error: ' + error.message)
      return false
    }
  }
  const getUserNFTs = async () => {
    try {
      const walletAddress = window.ethereum.selectedAddress
      let userNftBalance = await NFTContract.balanceOf(walletAddress)
      let tokenJsons = []
      for (let i = 0; i < userNftBalance; i++) {
        let tokenId = await NFTContract.tokenOfOwnerByIndex(walletAddress, i)
        let tokenUri = await NFTContract.tokenURI(tokenId)
        let data = await fetch(tokenUri)
        let jsonData = await data.json()
        tokenJsons.push(jsonData)
      }

      setUserTokenJsons(tokenJsons)
    } catch (error) {
      console.error('getUserNFTs error: ' + error.message)
      return false
    }
  }

  var previousBalance = 0

  function waitForUpdatedNFTBalance() {
    const poll = async (resolve) => {
      const walletAddress = await getCurrentWalletConnected()
      let userNftBalance = await NFTContract.balanceOf(walletAddress)
      userNftBalance = userNftBalance.toNumber()
      console.log(
        `polling user balance prev: ${previousBalance} -> ${userNftBalance}`,
        walletAddress,
        previousBalance,
        userNftBalance,
      )

      if (previousBalance < userNftBalance) resolve(userNftBalance)
      else setTimeout((_) => poll(resolve), 400)
    }
    return new Promise(poll)
  }

  const getLastUserNFT = async (amount) => {
    try {
      const walletAddress = await getCurrentWalletConnected()
      let userNftBalance = await waitForUpdatedNFTBalance()
      console.log(`got new balance:  ${userNftBalance}`)

      let mintedTokens = []
      let promises = []

      for (let i = 1; i <= amount; i++) {
        let index = userNftBalance - i
        promises.push(
          new Promise(async (resolve, reject) => {
            console.log('getLastUserNFT::checking token index:', index)
            let tokenId = await NFTContract.tokenOfOwnerByIndex(
              walletAddress,
              index,
            )
            let tokenUri = await NFTContract.tokenURI(tokenId)
            console.log('getLastUserNFT:::tokenUri', tokenUri)

            let data = null
            while (data == null) {
              try {
                data = await fetch(tokenUri)
                console.log('getLastUserNFT:::data', data)
              } catch (e) {
                console.log(e)
              }
            }
            let jsonData = await data.json()
            mintedTokens.push(jsonData)

            console.log('getLastUserNFT::jsonData', jsonData)
            resolve(1)
          }),
        )
      }
      Promise.all(promises).then(() => {
        console.log('getLastUserNFT::mintedTokens', mintedTokens)
        setMintedToken(mintedTokens)
      })
    } catch (error) {
      alert.info(
        <>
          Mint was successful, but failed to load nft from ipfs. Check the
          gallery in a few minutes!
        </>,
      )
      setMintedToken(null)
      console.error('getLastUserNFT error: ' + error.message)
      return false
    }
  }

  const getLGEDiscount = async () => {
    let userTerms = await getUserLGETerms()
    console.log('getLGEDiscount::userTerms', userTerms)
    console.log('getLGEDiscount::userTerms.termSec', userTerms.termSec)
    let discount = 0
    if (userTerms && userTerms.termSec && userTerms.termSec > 0) {
      discount = 35 + Math.sqrt(userTerms.termSec) / 800
      if (discount < 35) discount = 35
      else if (discount > 50) discount = 50
      return Math.min(discount, 50) / 100
    }
    return discount
  }

  const getDiscount = async (discountNFT) => {
    let nftDiscount = 0
    let priceMultiplier = 1
    let discount = 0
    let discountSourceStr = 'NFT'

    let lgeDiscount = await getLGEDiscount()
    console.log('mint::lgeDiscount', lgeDiscount)

    if (discountNFT) {
      nftDiscount = discountNFT.discount
    } else {
      nftDiscount = 0
    }
    console.log('mint::nftDiscount', nftDiscount)
    if (nftDiscount > lgeDiscount) {
      discount = nftDiscount
      discountSourceStr = 'NFT'
    } else {
      discount = lgeDiscount
      discountSourceStr = 'LGE'
    }
    priceMultiplier = 1 - discount

    return { priceMultiplier, discount, discountSourceStr }
  }

  const mint = async (ercTokenAddress, amount, price, discountNFT) => {
    let tx
    try {
      setMintedToken(null)
      setDivClass('nftcard__div')
      setMinting(true)

      const walletAddress = await getCurrentWalletConnected()
      previousBalance = await NFTContract.balanceOf(walletAddress)
      previousBalance = previousBalance.toNumber()

      // let { priceMultiplier, discount, discountSourceStr } = await getDiscount(
      //   discountNFT,
      // )

      //use selected nft address or the zero address if none selected
      let discountAddress = discountNFT ? discountNFT.address : ZERO

      // console.log('mint::discountNFT: ', discountNFT)
      // console.log('mint::mint discount address: ' + discountAddress)
      // let priceContract = await NFTContract.acceptedCurrencies(ercTokenAddress)
      // priceContract = ethers.utils.formatEther(priceContract)
      // console.log('mint::priceContract', priceContract)

      // let finalPrice = priceMultiplier * priceContract
      // console.log('mint::price multiplier', priceMultiplier)
      // console.log('mint::discount price', finalPrice)

      //call mint
      let config = {
        gasLimit: 2000000,
      }
      // if (price != null) {
      //   console.log(
      //     'mint::minting with price: ',
      //     (amount * finalPrice).toFixed(6),
      //   )
      //   // console.log(
      //   //   'mint::minting with price (big num): ',
      //   //   ethers.utils.parseUnits(`${amount * priceContract}`, 'ether'),
      //   // )
      //   config = {
      //     gasLimit: 3000000,
      //     value: ethers.utils.parseEther(`${(amount * finalPrice).toFixed(6)}`),
      //   }
      // }
      console.log('mint::config', config)

      // console.log('minting with discount', discountAddress)
      // console.log('mint::minting ercTokenAddress', ercTokenAddress)

      tx = await NFTContract.mint(
        ercTokenAddress,
        `${amount}`,
        discountAddress,
        config,
      )
      const receipt = await tx.wait()
      console.log('mint:: receipt', receipt)
      let link = `https://ftmscan.com/tx/${tx.hash}`
      setMinting(false)

      if (receipt.status == 1) {
        alert.success(
          <>
            Mint Success! <a href={link}>Click to view transaction.</a>
          </>,
        )
        updateContractTotalSupply()
        getLastUserNFT(amount)
      } else {
        setMinting(false)
        setDivClass('nftcard__div')
        alert.error(
          <>
            Mint Failed! <a href={link}>Click to view transaction.</a>
          </>,
        )
      }
    } catch (error) {
      setMinting(false)
      setDivClass('nftcard__div')
      // let link = `https://ftmscan.com/tx/${tx.hash}`

      alert.error(<>{error.message}</>)
      console.error('mint error: ' + error.message)
      return false
    }
  }

  const approveSpend = async (address) => {
    try {
      alert.info(<>Approving spend...</>)
      console.log('approveSpend', address)
      let contract = await ERC20.attach(address)
      const tx = await contract.approve(nftAddress, PAIN)
      let link = `https://ftmscan.com/tx/${tx.hash}`
      const receipt = await tx.wait()
      if (receipt.status === 0) {
        alert.error(
          <>
            Transaction Failed. <a href={link}>Click to view transaction.</a>
          </>,
        )
        throw new Error(link)
      } else if (receipt.status === 1) {
        console.log('Successfully approved spend')
        alert.success(
          <>
            Spend approved. <a href={link}>Click to view transaction.</a>
          </>,
        )
      }
      return true
    } catch (error) {
      console.error('approveSpend error: ' + error)
      alert.error(
        <>
          Transaction Failed. <a href={error}>Click to view transaction.</a>
        </>,
      )
      // DEBUG_MODE && console.log('approveSpend error');
      return false
    }
  }

  const getUserLGETerms = async () => {
    try {
      const walletAddress = await getCurrentWalletConnected()
      console.log('getUserLGETerms', walletAddress)
      var terms = await LGE.terms(walletAddress)
      let userShares = terms[0].toString()
      let userTermSec = terms[1].toNumber()
      userTermSec = userTermSec.toFixed(5)
      let userTerm = terms[1].div(86400).toNumber()
      // console.log('LGE userTerm', userTerm)
      setUserTerms({
        shares: userShares,
        term: userTerm,
        termSec: userTermSec,
      })
      return {
        shares: userShares,
        term: userTerm,
        termSec: userTermSec,
      }
    } catch (err) {
      console.error('getUserTerms error: ' + err.message)
    }
  }

  const getContractState = async () => {
    try {
      var oathAddress = await LGE.oath()
      var raised = await LGE.raised()
      let shareSupply = await LGE.shareSupply()

      let defaultTerm = await LGE.defaultTerm()
      let defaultPrice = await LGE.defaultPrice()

      raised = ethers.utils.formatEther(raised)
      shareSupply = shareSupply.toNumber()
      defaultTerm = defaultTerm.div(86400).toNumber()
      defaultPrice = ethers.utils.formatEther(defaultPrice)
      // console.log('oathAddress', oathAddress)
      // console.log('raised', raised)
      // console.log('shareSupply', shareSupply)
      // console.log('defaultTerm', defaultTerm)
      // console.log('defaultPrice', defaultPrice)
      setContractState({
        oathAddress: oathAddress,
        raised: raised,
        shareSupply: shareSupply,
        defaultTerm: defaultTerm,
        defaultPrice: defaultPrice,
      })
    } catch (err) {
      console.error('getContractState error: ' + err.message)
    }
  }

  const getCurrentWalletConnected = async () => {
    try {
      const addressArray = await provider.send('eth_requestAccounts', [])
      setWalletAddress(addressArray[0])
      return addressArray[0]
    } catch (err) {
      console.error(err)
    }
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0])
        } else {
          setWalletAddress('')
        }
      })
    } else {
      setStatus(
        <p>
          {' '}
          🦊{' '}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>,
      )
    }
  }
  return children
}
export default Crypto
