/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Animated } from 'react-animated-css'
import 'bootstrap/dist/css/bootstrap.min.css'
import MintPanel from '../../components/MintPanel/MintPanel'
import TypeWriter from '../../components/TypeWriter/TypeWriter'
import { useCookies } from 'react-cookie'
import './MintView.css'

function TextPrompt({ introPlayed, showText, delay }) {
  const [joke, setJoke] = useState(``)
  const getJoke = async () => {
    let res = await fetch(
      'https://v2.jokeapi.dev/joke/Miscellaneous,Dark,Pun,Spooky?blacklistFlags=nsfw,religious,political,racist,sexist,explicit',
    )
    console.log('joke ', res)
    let json = await res.json()
    console.log('joke json', json)

    return json
  }
  useEffect(async () => {
    let jokeObj = await getJoke()
    if (jokeObj) {
      if (jokeObj.type == 'single') {
        console.log(jokeObj.joke)
        setJoke(jokeObj.joke)
      } else {
        let joke = `${jokeObj.setup} ${jokeObj.delivery}`
        setJoke(joke)
      }
    }
  }, [showText])

  return (
    <div
      className="box1 sb8"
      style={{
        flex: 1,
        position: 'absolute',
        top: '-45%',
        left: '0%',
        transform: 'translate(-50%,-50%)',
      }}

      // style={{
      //   position: 'absolute',
      //   bottom: introPlayed ? '10%' : '5%',
      //   width: '550px',
      //   left: introPlayed ? '50%' : '47%',
      //   textAlign: 'center',
      //   transform: 'translate(-50%,-50%)',
      // }}
    >
      {showText && joke && (
        <TypeWriter
          speed={20}
          delay={delay + 250}
          content={
            introPlayed == true
              ? joke
              : ` 
                  Welcome to the Cursed Circus! 
                  Not sure why you're here. 
                  I guess for these freaks...`
          }
        />
      )}
    </div>
  )
}

function RingLeader({ leaderAnim, introPlayed, showText, delay }) {
  return (
    <div className={leaderAnim} style={{ flex: 2, animationDelay: 5000 }}>
      {showText && (
        <TextPrompt
          introPlayed={introPlayed}
          showText={showText}
          delay={delay}
        />
      )}
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDelay={2000}
        isVisible={true}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </Animated>
      <img
        style={{ zIndex: 10 }}
        height="250"
        width="250"
        src={'/assets/ringleader-idle01.gif'}
      />
    </div>
  )
}

function ClaimView({}) {
  const [showText, setShowText] = useState(true)
  const [cookies, setCookies] = useCookies(['nftSiteSettings'])
  let delay = 1000
  let cardsAnim = 'ringLeaderCardsIntro'
  let leaderAnim = 'ringLeaderIntro'
  let introPlayed = false
  if (cookies.introPlayed) {
    introPlayed = cookies.introPlayed == 'true'
    if (introPlayed) {
      delay = 1000
      cardsAnim = 'ringLeaderCards'
      leaderAnim = 'ringLeader'
    } else {
      delay = 5000
    }
  }

  useEffect(() => {
    setTimeout(() => setShowText(false), introPlayed ? 8000 : 13000)
  }, [])

  let minting = false
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'flex-end',
        margin: 'auto',
        height: '100%',
        width: '100%',
      }}
    >
      <div className={'mintItemDiv'}>
        <div className={cardsAnim} style={{ animationDelay: 5000 }}></div>
        <RingLeader
          leaderAnim={leaderAnim}
          introPlayed={introPlayed}
          showText={showText}
          delay={delay}
        />
      </div>

      <MintPanel />
    </div>
  )
}

export default ClaimView
