/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Animated } from 'react-animated-css'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './GalleryView.css'

import NFTFlipCard from '../../components/NFTFlipCard/NFTFlipCard'
import {
  useUserNFTs,
  useMintedNFTs,
  useMaxSupply,
  useTotalSupply,
} from '../../hooks/Web3Hooks'

function GalleryView({}) {
  const [showMyClowns, setShowMyClowns] = useState(true)
  const [searchText, setSearchText] = useState('')
  const mintedNFTs = useMintedNFTs()
  const userNFTs = useUserNFTs()
  const maxSupply = useMaxSupply()
  const totalSupply = useTotalSupply()

  const updateSearch = (e) => {
    console.log('updateSearch', e.target.value)
    setSearchText(e.target.value)
  }

  function getStatus() {
    let status = ''
    if (showMyClowns) {
      if (userNFTs.length == 0) {
        status = 'Loading your clowns...'
      } else {
        status = `You own ${userNFTs.length}/${totalSupply} minted clowns!`
      }
    } else {
      if (mintedNFTs.length == 0) {
        status = `Loading ${totalSupply} minted clowns...`
      } else {
        status = `${mintedNFTs.length} minted clowns loaded!`
      }
    }
    return status
  }

  function filterNftForDisplay() {
    let nftsToShow = showMyClowns ? [...userNFTs] : [...mintedNFTs]
    if (searchText.length > 0) {
      let filtered = nftsToShow.filter((nft) => nft.edition == searchText)
      if (filtered.length > 0) {
        nftsToShow = filtered
      }
    }
    nftsToShow.sort((a, b) => parseInt(a.edition) - parseInt(b.edition))
    return nftsToShow
  }

  let status = getStatus()
  let nftsToShow = filterNftForDisplay()

  return (
    <div className={'gallery__div'}>
      <div style={{ width: '100%' }}>
        <ButtonGroup aria-label="Basic example" style={{ marginBottom: 0 }}>
          <Button
            variant={showMyClowns ? 'secondary' : 'dark'}
            onClick={() => {
              setShowMyClowns(true)
            }}
          >
            My Clowns
          </Button>
          <Button
            variant={showMyClowns ? 'dark' : 'secondary'}
            onClick={async () => {
              setShowMyClowns(false)
              // await cryptoFunctions.getAllMintedNFTs()
            }}
          >
            All Clowns
          </Button>
        </ButtonGroup>
        <input
          onChange={updateSearch}
          style={{
            color: 'black',
            margin: '10px',
            height: '35px',
            borderRadius: '5px',
          }}
          type="text"
          id="fname"
          name="fname"
          placeholder="Search Clown ID..."
        />
        <div>{status}</div>
      </div>
      <>
        <Container
          fluid
          className="GalleryContainer"
          style={{
            height: '100%',
            margin: 'auto',
            overflowY: 'scroll',
          }}
        >
          <Row>
            {nftsToShow.map((clown, i) => (
              <Col style={{ height: '490px' }} key={i}>
                <Animated
                  animationIn="fadeInUp"
                  animationOut="fadeOut"
                  animationInDelay={i * 50}
                  isVisible={true}
                >
                  <NFTFlipCard
                    animationDelay={i * 0.2}
                    logoSrc={'/CursedCircus.png'}
                    tokenUri={clown}
                    imageLoadDelay={i * 50}
                  />
                </Animated>
              </Col>
            ))}
          </Row>
        </Container>
      </>
    </div>
  )
}

export default GalleryView
