import { useState } from 'react'

export default function AnimatedButton(props) {
    const [hover, setHover] = useState(false)
    const [mouseDown, setMouseDown] = useState(false)
  
  return (
    <img
      src={
        mouseDown
          ? props.imClicked
          : props.imNotClicked
      }
      onMouseEnter={() => setHover(false)}
      onMouseLeave={() => setHover(true)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      className={props.className}
      alt={props.alt}
      onClick={props.onClick}
    />
  )
}
