// export const ftmAddress = '0x51A1ceB83B83F1985a81C295d1fF28Afef186E02'
// export const oathAddress = '0x8198f5d8F8CfFE8f9C413d98a0A55aEB8ab9FbB7'
// export const lgeAddress = '0x96662f375a9734654cB57BbFeb31Db9dD7784A7F'
// export const nftAddress = '0x5067457698Fd6Fa1C6964e416b3f42713513B3dD'

// var startDate = new Date("2022-07-09 12:00:00 PM PDT");
// var endDate = new Date("2022-07-13 12:00:00 PM PDT");
export const startDate = new Date(1657407600000)// new Date("2022-07-09 4:00:00 PM PDT");
export const endDate = new Date(1657753200000)//new Date("2022-07-13 4:00:00 PM PDT");

//real addressses
export const ftmAddress = '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'
export const oathAddress = '0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6'
export const lgeAddress = '0x96662f375a9734654cB57BbFeb31Db9dD7784A7F'
export const nftAddress = '0x46DF5672a9bA6dd5A87C5f1224263F06392861F9' //test contract
export const lgeEnd = 1645729430
export const sounds = {
  clownHonk:
    'https://bytemasons-assets.s3.ap-southeast-2.amazonaws.com/clown-honk.mp3',
  drumRoll:
    'https://bytemasons-assets.s3.ap-southeast-2.amazonaws.com/comedy-drum-roll.mp3',
  honkTheme:
    'https://bytemasons-assets.s3.ap-southeast-2.amazonaws.com/honk.wav',
}

export const lgeAbi = [
  {
    inputs: [
      { internalType: 'address', name: '_oath', type: 'address' },
      { internalType: 'address', name: '_counterAsset', type: 'address' },
      { internalType: 'uint256', name: '_totalOath', type: 'uint256' },
      { internalType: 'uint256', name: '_beginning', type: 'uint256' },
      { internalType: 'uint256', name: '_end', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [],
    name: 'BASIS_POINTS',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'addr', type: 'address' },
      { internalType: 'uint256', name: 'threshold', type: 'uint256' },
      { internalType: 'uint256', name: 'limit', type: 'uint256' },
      { internalType: 'uint256', name: 'term', type: 'uint256' },
    ],
    name: 'addLicense',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'allocations',
    outputs: [
      { internalType: 'uint256', name: 'remaining', type: 'uint256' },
      { internalType: 'bool', name: 'activated', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'totalAmount', type: 'uint256' },
      { internalType: 'address[]', name: 'NFTs', type: 'address[]' },
      { internalType: 'uint256[]', name: 'indicies', type: 'uint256[]' },
      { internalType: 'bool', name: '_venture', type: 'bool' },
    ],
    name: 'batchPurchase',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'beginning',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'NFT', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
      { internalType: 'bool', name: 'venture', type: 'bool' },
    ],
    name: 'buy',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'claim',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'claimed',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'counterAsset',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'defaultPrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'defaultTerm',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'end',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
      { internalType: 'uint256', name: 'oldValue', type: 'uint256' },
      { internalType: 'uint256', name: 'weightedNew', type: 'uint256' },
      { internalType: 'uint256', name: 'weightedOld', type: 'uint256' },
    ],
    name: 'findWeightedAverage',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'totalAmount', type: 'uint256' },
      { internalType: 'address[]', name: 'NFTs', type: 'address[]' },
      { internalType: 'uint256[]', name: 'indicies', type: 'uint256[]' },
      { internalType: 'bool', name: 'venture', type: 'bool' },
    ],
    name: 'getBatchPricing',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'nftPerShare', type: 'uint256' },
          { internalType: 'uint256', name: 'nftTotalCost', type: 'uint256' },
          { internalType: 'uint256', name: 'nftTotalShares', type: 'uint256' },
          { internalType: 'uint256', name: 'perShare', type: 'uint256' },
          { internalType: 'uint256', name: 'totalAvailable', type: 'uint256' },
          { internalType: 'uint256', name: 'totalCost', type: 'uint256' },
        ],
        internalType: 'struct ElasticLGE.BatchPricingData',
        name: 'data',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'totalAmount', type: 'uint256' },
      { internalType: 'address[]', name: 'NFTs', type: 'address[]' },
      { internalType: 'uint256[]', name: 'indicies', type: 'uint256[]' },
      { internalType: 'bool', name: 'venture', type: 'bool' },
    ],
    name: 'getBatchTerms',
    outputs: [
      { internalType: 'uint256', name: 'nftTerm', type: 'uint256' },
      { internalType: 'uint256', name: 'term', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'NFT', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'getPricingData',
    outputs: [
      { internalType: 'uint256', name: 'available', type: 'uint256' },
      { internalType: 'uint256', name: 'perShare', type: 'uint256' },
      { internalType: 'uint256', name: 'totalCost', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'licenses',
    outputs: [
      { internalType: 'uint256', name: 'price', type: 'uint256' },
      { internalType: 'uint256', name: 'limit', type: 'uint256' },
      { internalType: 'uint256', name: 'term', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'multisig',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'oath',
    outputs: [{ internalType: 'contract IOath', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'raised',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'shareSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'terms',
    outputs: [
      { internalType: 'uint256', name: 'shares', type: 'uint256' },
      { internalType: 'uint256', name: 'term', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalOath',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOath', type: 'address' }],
    name: 'upgradeOath',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'venturePrice',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'ventureTerm',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
]
export const wFtmAbi = [
  {
    inputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'PauserAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'PauserRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'Unpaused',
    type: 'event',
  },
  {
    constant: true,
    inputs: [],
    name: 'ERR_INVALID_ZERO_VALUE',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'ERR_NO_ERROR',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'addPauser',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'deposit',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'isPauser',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'pause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'renouncePauser',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'value', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'unpause',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'withdraw',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
]
export const oathAbi = [
  {
    inputs: [
      { internalType: 'address', name: '_logic', type: 'address' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    stateMutability: 'payable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'previousAdmin',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'newAdmin',
        type: 'address',
      },
    ],
    name: 'AdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'beacon',
        type: 'address',
      },
    ],
    name: 'BeaconUpgraded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'implementation',
        type: 'address',
      },
    ],
    name: 'Upgraded',
    type: 'event',
  },
  { stateMutability: 'payable', type: 'fallback' },
  { stateMutability: 'payable', type: 'receive' },
]
export const nftAbi = [
  {
    inputs: [
      { internalType: 'string', name: '_name', type: 'string' },
      { internalType: 'string', name: '_symbol', type: 'string' },
      { internalType: 'string', name: '_initBaseURI', type: 'string' },
      { internalType: 'address', name: '_lpPair', type: 'address' },
      { internalType: 'address', name: '_royaltyAddress', type: 'address' },
      {
        internalType: 'uint256',
        name: '_royaltiesPercentage',
        type: 'uint256',
      },
      { internalType: 'uint256', name: '_maxSupply', type: 'uint256' },
      { internalType: 'uint256', name: '_maxMintAmount', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  { inputs: [], name: 'AmountLessThanOne', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: 'amtMint', type: 'uint256' },
      { internalType: 'uint256', name: 'maxMint', type: 'uint256' },
    ],
    name: 'AmountOverMax',
    type: 'error',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'totalCost', type: 'uint256' },
      { internalType: 'uint256', name: 'amtFTM', type: 'uint256' },
    ],
    name: 'InsufficientFTM',
    type: 'error',
  },
  { inputs: [], name: 'MintPaused', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: 'supplyLeft', type: 'uint256' },
      { internalType: 'uint256', name: 'amtMint', type: 'uint256' },
    ],
    name: 'NotEnoughMintsLeft',
    type: 'error',
  },
  { inputs: [], name: 'TokenNotAuthorized', type: 'error' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'approved',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'operator',
        type: 'address',
      },
      { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' },
    ],
    name: 'ApprovalForAll',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'acceptedCurrencies',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'acceptedCurrenciesInput',
        type: 'address[]',
      },
      { internalType: 'uint256[]', name: 'prices', type: 'uint256[]' },
    ],
    name: 'addCurrency',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'baseExtension',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'collectionsWithDiscount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'operator', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'lpPair',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxMintAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
      { internalType: 'address', name: 'collection', type: 'address' },
    ],
    name: 'mint',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bool', name: '_state', type: 'bool' }],
    name: 'pausePublic',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'premintTokens',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'publicPaused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_tokenId', type: 'uint256' },
      { internalType: 'uint256', name: '_salePrice', type: 'uint256' },
    ],
    name: 'royaltyInfo',
    outputs: [
      { internalType: 'address', name: 'Receiver', type: 'address' },
      { internalType: 'uint256', name: 'royaltyAmount', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
      { internalType: 'bytes', name: '_data', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'operator', type: 'address' },
      { internalType: 'bool', name: 'approved', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'string', name: '_newBaseExtension', type: 'string' },
    ],
    name: 'setBaseExtension',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'string', name: '_newBaseURI', type: 'string' }],
    name: 'setBaseURI',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: '_collectionAddresses',
        type: 'address[]',
      },
      { internalType: 'uint256[]', name: '_discounts', type: 'uint256[]' },
    ],
    name: 'setDiscountCollections',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'uint256', name: 'index', type: 'uint256' },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_owner', type: 'address' }],
    name: 'walletOfOwner',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
]

export const clowns = [
  {
    name: 'Weirdo',
    description:
      'Name: Weirdo; Bio: Has a billion friends and makes the rest of us feel a little bit better about ourselves.; Age: 22; Net Worth: $9,987.00 ; Cause Of Death: Stampede',
    image: '/assets/UnknownClown.png',
    dna: '3faa5e053505e9c9ae99ddd4873538f8c3e70b7f',
    edition: '?',
    date: 1652443615747,
    attributes: [
      {
        trait_type: 'Backgrounds',
      },
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
      {
        trait_type: 'Accessories',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio:
      'Has a billion friends and makes the rest of us feel a little bit better about ourselves.',
    age: '22',
    networth: '$9,987.00 ',
    causeofdeath: 'Stampede',
  },
  {
    name: "Miles 'The Mouth' High",
    description:
      "Name: Miles 'The Mouth' High; Bio: This fellow gives nothing away, he is the ultimate jerk.; Age: 20; Net Worth: $33,625.00 ; Cause Of Death: Loneliness",
    image: '/assets/UnknownClown.png',
    dna: 'a7252487e29246cf32f6ab3a059e7f8476e1b06d',
    edition: '?',
    date: 1652443615578,
    attributes: [
      {
        trait_type: 'Backgrounds',
      },
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
      {
        trait_type: 'Accessories',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'This fellow gives nothing away, he is the ultimate jerk.',
    age: '20',
    networth: '$33,625.00 ',
    causeofdeath: 'Loneliness',
  },
  {
    name: 'Stupid Cat and the Catfight',
    description:
      'Name: Stupid Cat and the Catfight; Bio: Never has the world been so broken up in comedy.; Age: 49; Net Worth: $37,857.00 ; Cause Of Death: Abducted (Aliens)',
    image: '/assets/UnknownClown.png',
    dna: 'ebc595757f6f113929a1e123a03551588a28b819',
    edition: '?',
    date: 1652443615405,
    attributes: [
      {
        trait_type: 'Backgrounds',
      },
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
      {
        trait_type: 'Accessories',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'Never has the world been so broken up in comedy.',
    age: '49',
    networth: '$37,857.00 ',
    causeofdeath: 'Abducted (Aliens)',
  },
  {
    name: 'Custer Hickok',
    description:
      'Name: Custer Hickok; Bio: The great comedic actor will surely steal the show.; Age: 57; Net Worth: $11,606.00 ; Cause Of Death: Batman',
    image: '/assets/UnknownClown.png',
    dna: 'aa16ed07cc9563a2927920238cfbdf0b34100884',
    edition: '?',
    date: 1652443615238,
    attributes: [
      {
        trait_type: 'Backgrounds',
      },
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
      {
        trait_type: 'Accessories',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'The great comedic actor will surely steal the show.',
    age: '57',
    networth: '$11,606.00 ',
    causeofdeath: 'Batman',
  },
  {
    name: 'The Goose',
    description:
      'Name: The Goose; Bio: A nickname for Joey Buttafuco. Not even I can explain it.; Age: 35; Net Worth: $17,007.00 ; Cause Of Death: Extreme Slapstick',
    image: '/assets/UnknownClown.png',
    dna: '288f514e10c8ebf9e7c26b71b7e9e98b93655e1a',
    edition: '?',
    date: 1652443610082,
    attributes: [
      {
        trait_type: 'Backgrounds',
      },
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
      {
        trait_type: 'Accessories',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'A nickname for Joey Buttafuco. Not even I can explain it.',
    age: '35',
    networth: '$17,007.00 ',
    causeofdeath: 'Extreme Slapstick',
  },
  {
    name: 'Jimmy Doyle',
    description:
      "Name: Jimmy Doyle; Bio: The man is tough and wise, even when he's thinking up a punchline.; Age: 33; Net Worth: $16,303.00 ; Cause Of Death: Fatal Dishonor",
    image: '/assets/UnknownClown.png',
    dna: 'fe56aa546daead0cab9052607801b18293bd23fe',
    edition: '?',
    date: 1652443606767,
    attributes: [
      {
        trait_type: 'Backgrounds',
      },
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
      {
        trait_type: 'Accessories',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: "The man is tough and wise, even when he's thinking up a punchline.",
    age: '33',
    networth: '$16,303.00 ',
    causeofdeath: 'Fatal Dishonor',
  },
  {
    name: "Earl 'Governor Jefferson' Daugherty",
    description:
      "Name: Earl 'Governor Jefferson' Daugherty; Bio: The armless green man; Age: 32; Net Worth: $94,273.00 ; Cause Of Death: Extreme Slapstick",
    image: '/assets/UnknownClown.png',
    dna: '576ff3715ab97304592f62842af22919b1b84889',
    edition: '?',
    date: 1652443400179,
    attributes: [
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'The armless green man',
    age: '32',
    networth: '$94,273.00 ',
    causeofdeath: 'Extreme Slapstick',
  },
  {
    name: "Jack 'One-Ounce Tom' Benny",
    description:
      "Name: Jack 'One-Ounce Tom' Benny; Bio: A lion on its birthday; Age: 25; Net Worth: $84,665.00 ; Cause Of Death: Big Top Blaze",
    image: '/assets/UnknownClown.png',
    dna: '5ffc0e9e86242400d28525c36a3b7621efd2470f',
    edition: '?',
    date: 1652443391248,
    attributes: [
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'A lion on its birthday',
    age: '25',
    networth: '$84,665.00 ',
    causeofdeath: 'Big Top Blaze',
  },
  {
    name: "D-Motivator 'Extreme Blazer'",
    description:
      "Name: D-Motivator 'Extreme Blazer'; Bio: He is said to sleep in a disheveled band uniform, and his overall appearance seems like the recollection of a hallucination; Age: 51; Net Worth: $68,419.00 ; Cause Of Death: Got lost",
    image: '/assets/UnknownClown.png',
    dna: '1a1ce42b3555919a651cf9d12b16a70d45e1500e',
    edition: '?',
    date: 1652443377076,
    attributes: [
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio:
      'He is said to sleep in a disheveled band uniform, and his overall appearance seems like the recollection of a hallucination',
    age: '51',
    networth: '$68,419.00 ',
    causeofdeath: 'Got lost',
  },
  {
    name: "Sanchez 'The Satyr'",
    description:
      "Name: Sanchez 'The Satyr'; Bio: Balloons! Balloons! Oh how he loved them!; Age: 69; Net Worth: Infinite; Cause Of Death: Told jokes at a funeral",
    image: '/assets/UnknownClown.png',
    dna: '2ef8b8a962ef527e9723f2cf3301771b7ec2d4c8',
    edition: '?',
    date: 1652443309934,
    attributes: [
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio: 'Balloons! Balloons! Oh how he loved them!',
    age: '69',
    networth: 'Infinite',
    causeofdeath: 'Told jokes at a funeral',
  },
  {
    name: 'THE PONYMAN',
    description:
      "Name: THE PONYMAN; Bio: The Pony Man is a hit, only a few people can understand him and everybody thinks he's a freaking moron. But there's something about The Pony Man that keeps people coming back for more.; Age: ?; Net Worth: ??; Cause Of Death: Told jokes at a funeral",
    image: '/assets/UnknownClown.png',
    dna: '055eb32356abf915b26fa3c717dc7f04869d1541',
    edition: '?',
    date: 1652443310939,
    attributes: [
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio:
      "The Pony Man is a hit, only a few people can understand him and everybody thinks he's a freaking moron. But there's something about The Pony Man that keeps people coming back for more.",
    age: '?',
    networth: '??',
    causeofdeath: 'Told jokes at a funeral',
  },
  {
    name: "James 'Snapper' Knight",
    description:
      "Name: James 'Snapper' Knight; Bio: An old man with a single regret, that he never owned a t-shirt that said Ask me about my time on the STREET CRIME SCENE�; Age: 33; Net Worth: $200,000.00 ; Cause Of Death: Killed during a an arcade robbery.",
    image: '/assets/UnknownClown.png',
    dna: 'bdfd8dfb0822ec3e5b7a1caff3e928227220cb71',
    edition: '?',
    date: 1652443317750,
    attributes: [
      {
        trait_type: 'Bodies',
      },
      {
        trait_type: 'Heads',
      },
      {
        trait_type: 'Hair',
      },
      {
        trait_type: 'Mouths',
      },
      {
        trait_type: 'Eyes',
      },
      {
        trait_type: 'Nose',
      },
    ],
    compiler: 'HashLips Art Engine',
    bio:
      'An old man with a single regret, that he never owned a t-shirt that said Ask me about my time on the STREET CRIME SCENE�',
    age: '33',
    networth: '$200,000.00 ',
    causeofdeath: 'Killed during a an arcade robbery.',
  },
]

export const nftDiscounts = [
  {
    name: 'Pop Pussies',
    image: '/NFTPartners/poppussies/1.png',
    address: '0x5ba5168a88b4f1c41fe71f59ddfa2305e0dbda8c',
    COD: 'Drowned in pussy',
    website: 'https://www.0xcinn.com/',
    link: 'https://nftkey.app/collections/poppussies/',
    description:
      '750 Pixel purrfect pussies wielding great power, they have formed powerful clans to pay homage to the spirits that birthed their world. Which clan will you join?',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'OG Skullys',
    image: '/NFTPartners/ogskullys/1.png',
    address: '0x25ff0d27395A7AAD578569f83E30C82a07b4ee7d',
    COD: 'Time',
    website: 'https://popskullys.com/',
    link: 'https://nftkey.app/collections/skullys/',
    description:
      'A virtual cult worshiping the arts and technology through rituals and spells.',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'BITSHADOWZ',
    image: '/NFTPartners/bitshadowz/1.png',
    address: '0xe92752C25111DB288665766aD8aDB624CCf91045',
    COD: 'Killed during a an arcade robbery.',
    website: 'https://www.southernempire.art/bitshadowz/',
    link: 'https://nftkey.app/collections/bitshadowz/',
    description:
      'The SHADOWZ have been captured by RETRO, the evil master of arcades! The SOUTHERN EMPIRE has issued the call to save the SHADOWZ from his neon domain! Visit https://southernempire.art to find out more!',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'BitWitches: Banners',
    image: '/NFTPartners/bitwitches/1.png',
    address: '0xC369d0c7f27c51176dcb01802D6Bca2b3Eb0b8dC',
    COD: 'Death by Ritual',
    website: 'https://natureoracle.com/',
    link: 'https://nftkey.app/collections/bitwitchesoraclebanners/',
    description:
      'Inspired by the worlds of Nature Oracle + Witches Oracle. The bitwitches live in harmony with the cycles of the moon, the rhythm of nature and the sounds of the forests.',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'Flamingo Social Club Passports',
    image: '/NFTPartners/flamingos/1.png',
    address: '0x5ed7893b8cf0f9199aa2760648779cb5d96716ae',
    COD: 'too much pixie dust',
    website: 'https://www.pinkflamingosocial.club/',
    link: 'https://nftkey.app/collections/mingopassport/',
    description:
      "The 'MINGO PASSPORT' is the official multi-chain passport of Pink Flamingo Social Club. Don't lose it, these cannot be replaced and will come in handy for 'The Great Migration'.",
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'FTM FROG Family',
    image: '/NFTPartners/ftmfrogfamily/1.png',
    address: '0xa70aa1f9da387b815Facd5B823F284F15EC73884',
    COD: 'licked a frog',
    website: 'https://froqorion.com',
    link: 'https://nftkey.app/collections/ftmfrogfamily/',
    description:
      'Frogs happily ribbiting, squeaking and croaking in the fantom blockchain.',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'Pocket Pals',
    image: '/NFTPartners/pocketpals/1.gif',
    address: '0x590e13984295df26c68f8c89f32fcf3a9f08177f',
    COD: 'skinned',
    website: 'www.pocketpals.io',
    link:
      'https://paintswap.finance/marketplace/collections/0x590e13984295df26c68f8c89f32fcf3a9f08177f',
    description:
      'An out-of-this-world, evolving NFT game-piece in an expanding universe of games and challenges',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'Riot Goools',
    image: '/NFTPartners/riotgools/1.png',
    address: '0x4f504ab2e7b196a4165ab3db71e75cb2b37070e0',
    COD: 'Died at a riot',
    website: 'https://riotgoools.com/',
    link: 'https://nftkey.app/artists/elle/',
    description:
      'Just some morose rebel goools starting some goool bands, printing some zines, and exploring the Gooolniverse.',
    Airdrop: 90,
    discount: 0.33,
  },
  {
    name: 'Hammsteria',
    image: '/NFTPartners/hammsteria/1.png',
    address: '0x0ef9d39bbbed9c4983ddc4a1e189ee4938d837b3',
    COD: 'Wet Tail',
    website: '',
    link: '',
    description: '',
    Airdrop: 30,
    discount: 0.33,
  },
  {
    name: 'Cosmic Horrors',
    image: '/NFTPartners/cosmic-horrors/1.png',
    address: '0x68874C562EfbBc6e2487FA77B7700064d209D4CA',
    COD: 'Interdimensional de-manifestation.',
    website: 'cosmichorrors.wtf',
    link:
      'https://paintswap.finance/marketplace/collections/0x68874c562efbbc6e2487fa77b7700064d209d4ca',
    description: '',
    Airdrop: 30,
    discount: 0.5,
  },
]

export const testURL2 =
  'https://sen3h2ah78.execute-api.ap-southeast-2.amazonaws.com/default/nftClownQuiz'
export const testURL =
  'https://oief7epztp5wo7kgj4ve246fca0jjufz.lambda-url.ap-southeast-2.on.aws/'
export const quizQuestions = [
  {
    id: 1,
    question: "Who's the coolest bytemason of them all?",
    answers: ['Bebis', 'Munchies', 'Kevin Barker', 'Corval', 'Noob', 'Mace'],
    correct: 1,
  },
  {
    id: 2,
    question: 'How many developers work for the Bytemasons?',
    answers: ['1', '5', '20', '100', '1000'],
    correct: 2,
  },
  {
    id: 3,
    question: 'What is a bonding curve?',
    answers: ['poo', 'Some fancy finance shit.', "Don't know. Don't care."],
    correct: 2,
  },
  {
    id: 4,
    question: "Who's the lamest bytemason of them all?",
    answers: ['Bebis', 'Munchies', 'Kevin Barker', 'Corval', 'Noob', 'Mace'],
    correct: 0,
  },
  {
    id: 5,
    question: 'How many strategists work for the Bytemasons?',
    answers: ['1', '5', '20', '100', '1000'],
    correct: 1,
  },
  {
    id: 6,
    question: 'What is a lp token?',
    answers: ['poo', 'Some fancy finance shit.', "Don't know. Don't care."],
    correct: 2,
  },
  {
    id: 7,
    question: "How many pizza's can Munchies eat in one sitting?",
    answers: ['1', '2', '3', '4', '30', '2x His body weight.'],
    correct: 3,
  },
]
