import { useState, useEffect } from 'react'

var animKey = null

export default function TypeWriter({
  content = '',
  speed = 400,
  delay = 1000,
}) {
  const [displayedContent, setDisplayedContent] = useState('')
  const [index, setIndex] = useState(0)

  useEffect(() => {
    /*Create a new setInterval and store its id*/
    setTimeout(() => {
      animKey = setInterval(() => {
        setIndex((index) => {
          /*This setState function will set the index
          to index+1 if there is more content otherwise
          it will destory this animation*/

          if (index >= content.length - 1) {
            clearInterval(animKey)
            return index
          }
          return index + 1
        })
      }, speed)
    }, delay)
    return function cleanup() {
      clearInterval(animKey)
    }
  }, [])

  useEffect(() => {
    setDisplayedContent(displayedContent + content[index])
  }, [index])

  return (
    <p
      style={{
        fontFamily: 'Monospace',
        fontSize: '15px',
        textAlign: 'left',
      }}
    >
      {displayedContent}
    </p>
  )
}
