/* eslint-disable */
import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { nftDiscounts } from '../../general/variables'
import { Animated } from 'react-animated-css'
import { useRecoilState } from 'recoil'

import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { userTokenJsonsAtom } from '../../recoil/atoms'
function PartnersView({}) {
  return (
    <div
      style={{
        position: 'absolute',
        margin: 20,
        padding: 20,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '98%',
        minWidht: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Container
        fluid
        className="GalleryContainer"
        style={{
          height: '100%',
          width: '100vh',
          overflowY: 'scroll',
        }}
      >
        <Row>
          <h1>Project Partners</h1>
        </Row>

        {nftDiscounts.map((nft, i) => (
          <Row
            style={{
              width: '100%',
              margin: '15px',
            }}
          >
            <Animated
              animationIn={i % 2 == 0 ? 'slideInLeft' : 'slideInRight'}
              animationOut="fadeOut"
              animationInDelay={i * 500}
              isVisible={true}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div
                style={{
                  position: 'relative',
                  flex: 0.6,
                  height: 250,
                  width: 250,
                  minWidth: 248,
                  overflowX: 'hidden',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    zIndex: 10,
                    left: '50%',
                    transform: 'translate(-50%,0)',
                  }}
                  height="250"
                  src={nft.image}
                />
              </div>
              <div
                style={{
                  flex: 2,
                  border: '1px solid black',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '5px'
                }}
              >
                <div>
                  <h4>{nft.name}</h4>
                </div>
                <div>
                  <p>{nft.description}</p>
                </div>
                <div>
                  <h6 style={{ color: 'red' }}>Cause of Death</h6>
                  <p>{nft.COD}</p>
                </div>
                <a
                  href={nft.website}
                  target="_blank"
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    position: 'absolute',
                    bottom: '5px',
                    right: '75px',
                  }}
                >
                  Website
                </a>
                <a
                  href={nft.link}
                  target="_blank"
                  style={{
                    cursor: 'pointer',
                    color: 'green',
                    position: 'absolute',
                    bottom: '5px',
                    right: '25px',
                  }}
                >
                  Buy
                </a>
              </div>
            </Animated>
          </Row>
        ))}
      </Container>
    </div>
  )
}

export default PartnersView
