/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { testURL, startDate, endDate } from '../../general/variables'
import { Animated } from 'react-animated-css'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  walletAddressAtom,
  walletAddressShortSelector,
} from '../../recoil/atoms'
import { useUserTokenIds, getAllWalletTokenIds } from '../../hooks/Web3Hooks'
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import './QuizView.css'

const numQuestions = 40

function QuizView({ }) {
  const [walletAddress] = useRecoilState(walletAddressAtom)
  const walletAddressShort = useRecoilValue(walletAddressShortSelector)
  const userTokenIds = useUserTokenIds(walletAddress)

  const intervalRef = useRef(0)
  const [usedTokens, setUsedTokens] = useState([])
  const [userQuestions, setUserQuestions] = useState([])
  const [leaderBoard, setLeaderBoard] = useState([])
  const [quizQuestions, setQuizQuestions] = useState([])
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(0)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [started, setStarted] = useState(false)
  const [quizSubmitted, setFinished] = useState(false)
  const [allQuestionAnswered, setAllQuestionAnswered] = useState(false)
  const emptyAnswers = new Array(numQuestions)
  const [answers, setAnswers] = useState(emptyAnswers)
  const [quizEnded, setQuizEnded] = useState(false)

  const interval = useRef(0)

  let usedUserToken = userTokenIds.filter((id) => usedTokens.includes(id))
  //console.log('userTokenIds', userTokenIds)
  // console.log('usedTokens', usedTokens)
  let hasValidClownToken = true
  let ownsClown = true
  if (userTokenIds.length == 0) ownsClown = false
  if (usedUserToken.length > 0) hasValidClownToken = false

  // async function blackListTeamWallets() {
  //   let blacklistIds = []
  //   for (let wallet of teamWallets) {
  //     let ids = await getAllWalletTokenIds(wallet)
  //     console.log(`blackListTeamWallets::${wallet}: `, ids)

  //     blacklistIds = [...blacklistIds, ...ids]
  //   }
  //   console.log('blackListTeamWallets::blacklist', blacklistIds)
  // }

  // useEffect(() => {
  //   blackListTeamWallets()
  // }, [])

  function getQuizQuestions() {
    // console.log("getting quiz questions")
    fetch(testURL)
      .then(res => res.json())
      .then(res => {
        let questions = res.data
        // console.log("Got quiz questions", questions)
        setQuizQuestions(questions)
      })
      .catch(e => console.log(e))
  }

  function checkTime() {
    let now = new Date();
    var secondsEnd = (endDate.getTime() - now.getTime()) / 1000;
    if (secondsEnd <= 0) {
      setQuizEnded(true)
      clearInterval(interval)
      return
    }
  }

  useEffect(() => {
    //console.log("quis questions.length", quizQuestions.length)
    if (quizQuestions.length == 0) {
      getQuizQuestions()
    }
    if (interval.current == 0)
      interval.current = setInterval(checkTime, 500)
    checkTime();

  }, [])

  useEffect(() => {
    if (quizEnded && started && !quizSubmitted) {
      submitQuiz();
      alert("Sorry time is up. Your answers have been submitted. Good luck!")
    }
  }, [quizEnded])
  useEffect(() => {
    init()
  }, [quizQuestions])

  useEffect(() => {

    if (allQuestionAnswered) {
      let element = document.getElementById('submitDiv')
      if (element)
        element.scrollIntoView();
    } else {
      let index = Math.max(answers.indexOf(undefined), 0)
      let element = document.getElementById('questionRow' + index)
      if (element)
        element.scrollIntoView();
    }
  }, [answers])

  useEffect(() => {
    if (started) {
      intervalRef.current = setInterval(() => {
        setElapsedTime(Date.now() / 1000 - startTime)
      }, [1000])
    }
  }, [started])

  useEffect(async () => {
    init()
  }, [walletAddress])

  const finishQuiz = () => {
    let element = document.getElementById('instructions')
    //console.log("Got element: ", element)
    if (element) {
      element.scrollIntoView();
      //console.log("scrolledToItem")
    }

    setFinished(true)
    init();
  }

  const setRandomQuestion = () => {

    let tempQuestions = [...quizQuestions]
    let finalQuestions = []
    for (let i = 0; i < numQuestions; i++) {
      let index = Math.random() * tempQuestions.length - 1
      finalQuestions.push(tempQuestions.splice(index, 1)[0])
    }
    //console.log('setRandomQuestion', finalQuestions)

    setUserQuestions(finalQuestions)
    setAnswers(new Array(finalQuestions.length))
    return finalQuestions
  }

  const updateAnswer = (questionIndex, answerIndex) => {
    if (quizSubmitted) return
    //console.log(answers, questionIndex, answerIndex)
    let newAnswers = [...answers]
    newAnswers[questionIndex] = answerIndex
    setAnswers(newAnswers)
    if (!newAnswers.includes(undefined)) {
      setAllQuestionAnswered(true)
    }
  }

  const startQuiz = () => {
    let generatedQuestions = setRandomQuestion()

    let startTime = Date.now() / 1000
    setStartTime(startTime)
    setStarted(true)
    let results = {
      operation: 'put',
      TableName: 'nft-clown-quiz-results',
      Item: {
        wallet: walletAddress,
        start_time_sec: startTime,
        user_question_ids: generatedQuestions.map((q) => q.id),
        token_ids: userTokenIds,
      },
      ReturnValues: 'ALL_OLD',
    }
    const options = {
      method: 'POST',
      body: JSON.stringify(results),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    fetch(testURL, options)
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }

  const submitQuiz = () => {
    let finishTime = Date.now() / 1000
    let results = {
      wallet: walletAddress,
      answers: answers,
      end_time_sec: finishTime,
    }
    const options = {
      method: 'PUT',
      body: JSON.stringify(results),
      headers: {
        'Content-Type': 'application/json',
      },
    }
    fetch(testURL, options)
      .then((res) => res.json())
      .then((res) => {
        //console.log(res)
        setEndTime(res.Attributes.end_time_sec)
        finishQuiz()
      })
      .catch((err) => console.log(err))
  }

  async function init() {
    if (!quizQuestions || quizQuestions.length == 0) return
    //console.log('initialising data..', quizQuestions)

    const url = new URL(testURL)
    url.searchParams.append('wallet', walletAddress)
    //console.log(url.href)
    fetch(url.href)
      .then((res) => res.json())
      .then((res) => {
        let entries = res.data
        let blackListIds = res.blackListIds

        //console.log('blackListIds', blackListIds)
        //console.log('entries', entries)

        generateLeaderBoard(entries)
        //get all user test results from db
        //console.log(entries)

        //Check for used tokens
        let temp = entries.map((item) => item.token_ids)
        //console.log('usedUserToken:temp', temp)

        let used = []
        for (let i = 0; i < temp.length; i++) {
          used = [...used, ...temp[i]]
        }
        //console.log('usedUserToken:used', used)
        used = [...used, ...blackListIds]
        setUsedTokens(used)

        let userDataArr = entries.filter((it) => it.wallet == walletAddress)
        //console.log(userDataArr)
        if (userDataArr.length > 0) {
          let userData = userDataArr[0]
          //console.log('useEffect', userData)
          if (userData.start_time_sec) {
            setStartTime(userData.start_time_sec)
            setStarted(true)
          } else {
            setStartTime(0)
            setStarted(false)
          }
          let generatedQuestions = []
          if (userData.user_question_ids) {
            let qIds = userData.user_question_ids
            for (let i = 0; i < qIds.length; i++) {
              generatedQuestions.push(
                quizQuestions.filter((q) => q.id == qIds[i])[0]
              )
            }
            //console.log('existing user questions:', quizQuestions, generatedQuestions, userData.user_question_ids)
            setUserQuestions(generatedQuestions)
          } else {
            console.error('no user questions')
          }

          if (userData.end_time_sec) {
            setEndTime(userData.end_time_sec)
            setAnswers(userData.answers)
            setFinished(true)
          } else {
            setEndTime(0)
            setAnswers(new Array(generatedQuestions.length))
            setFinished(false)
          }
        }
      })
      .catch((err) => console.log(err))
  }

  function generateLeaderBoard(res) {
    let completedQuizes = res.filter(
      (obj) =>
        obj.hasOwnProperty('start_time_sec') &&
        obj.hasOwnProperty('end_time_sec') &&
        obj.hasOwnProperty('answers') &&
        obj.hasOwnProperty('marks'),
    )
    /**
     * Sort by
     * score
     * faster time
     * earliest submission
     */
    const score = (obj) => {
      return obj.marks.filter((m) => m == true).length
    }
    const completeTime = (obj) => {
      return obj.end_time_sec - obj.start_time_sec
    }
    const finishTime = (obj) => {
      return obj.end_time_sec
    }
    completedQuizes = completedQuizes.map((obj) => {
      obj['score'] = score(obj)
      obj['completeTime'] = completeTime(obj)
      obj['finishTime'] = finishTime(obj)
      return obj
    })
    //console.log('completedQuizes before', completedQuizes)

    const sortComplex = (arr = []) => {
      arr.sort(function (a, b) {
        return (
          b.score - a.score ||
          a.completeTime - b.completeTime ||
          a.finishTime - b.finishTime
        )
      })
    }
    sortComplex(completedQuizes)
    // console.log(
    //   'completedQuizes after sort',
    //   completedQuizes.map((obj) => {
    //     return {
    //       wallet: obj.wallet,
    //       score: obj.score,
    //       completeTime: obj.completeTime,
    //       finishTime: obj.finishTime,
    //     }
    //   }),
    // )

    let leaderboard = completedQuizes.map((obj) => {
      return {
        wallet: obj.wallet,
        score: obj.score,
        completeTime: obj.completeTime,
        finishTime: obj.finishTime,
      }
    })
    setLeaderBoard(leaderboard)
  }

  let startStr
  let endStr
  let testTime
  if (startTime) {
    let date = new Date(startTime * 1000)
    startStr = date.toLocaleString()
  }
  if (endTime) {
    let date = new Date(endTime * 1000)
    endStr = date.toLocaleString()
    testTime = endTime - startTime
  }

  if (!walletAddress || walletAddress.length == 0) {
    return (
      <div className="quiz__intro">
        <Row style={{ backgroundColor: '#000000a5', border: '1px solid red' }}>
          No wallet detected!
        </Row>
      </div>
    )
  }

  if (!ownsClown) {
    return (
      <div className="quiz__intro">
        <Row style={{ backgroundColor: '#000000a5', border: '1px solid red' }}>
          <p>It looks like this wallet does not own any clowns!</p>
          <p>One on NFT an nft marketplace to participate.</p>
        </Row>
      </div>
    )
  }
  //console.log("answers", answers)
  let answeredQuestions = answers.filter(a => !isNaN(a))
  let numberAnsweredQuestions = answeredQuestions.length
  return (
    <div className="quiz__intro" style={{
      margintop: 35
    }}>
      <Container
        id="scrollContainer"
        fluid
        style={{
          margintop: 35,
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        {!hasValidClownToken && (
          <Row
            style={{ backgroundColor: '#000000a5', border: '1px solid red', margin: '25px' }}
            id="completionNotice"
          >
            <p>You have already completed with the following Token ID's:</p>
            <p>{usedUserToken.join(', ')}</p>
          </Row>
        )}

        <Row style={{ backgroundColor: '#000000a5', border: '1px solid red' }} id="instructions">
          {started && !quizSubmitted && (
            <h6>
              <span style={{ color: 'red' }}>Elapsed time:</span>{' '}
              {elapsedTime.toFixed(2)}s
            </h6>
          )}
          <h1 style={{ textAlign: 'center' }}>Air Drop Quiz</h1>
          <p>
            People with the fastest time and most number of correct answers will
            win! Tie breaks will be determined by who submitted their quiz
            first.
          </p>
          <p>
            Once you start the quiz there is no stopping! Answers will not be
            saved until you submit them.
          </p>
          <p>
            Connected wallet:{' '}
            <span style={{ color: 'lightblue' }}>{walletAddress}</span>
          </p>
          {startStr && (
            <p style={{ color: 'lightblue' }}>Start Time: {startStr}</p>
          )}
          {endStr && <p style={{ color: 'lightblue' }}>End Time: {endStr}</p>}
          {testTime && (
            <h5 style={{ color: 'lightgreen' }}>
              {' '}
              You completed the test in {testTime.toFixed(2)} seconds.
            </h5>
          )}
        </Row>

        {leaderBoard.length > 0 && (
          <Row
            style={{
              backgroundColor: '#000000a5',
              border: '1px solid green',
              textAlign: 'left',
              marginTop: '20px',
              padding: 20
            }}
          >
            <h4 style={{ textAlign: 'center' }}>Current Leaderboard</h4>
            <div
              style={{
                width: '100%',
                maxHeight: '200px',
                overflowY: 'scroll',
              }}
            >
              <table
                style={{
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <th></th>
                    <th>Wallet</th>
                    <th>Score</th>
                    <th>Completion Time</th>
                    <th style={{ textAlign: 'left' }}>Submission Time</th>
                  </tr>
                  {leaderBoard.map((res, i) => (
                    <tr
                      style={{
                        backgroundColor:
                          res.wallet == walletAddress ? 'green' : 'transparent',
                      }}
                    >
                      <td>{i + 1}.</td>
                      <td>
                        {res.wallet == walletAddress
                          ? walletAddressShort
                          : res.wallet}
                      </td>
                      <td>
                        {res.score}/{numQuestions}
                      </td>
                      <td>
                        {res.completeTime.toFixed(2)}s (+
                        {(
                          res.completeTime - leaderBoard[0].completeTime
                        ).toFixed(2)}
                        )
                      </td>
                      <td>
                        +
                        {(res.finishTime - leaderBoard[0].finishTime).toFixed(
                          2,
                        )}
                        s
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Row>
        )}

        {(started && userQuestions) && (
          <div style={{ position: 'fixed', bottom: '-40px', left: '50%', transform: 'translate(-50%,0%)', zIndex: 1000 }}>
            <p >{numberAnsweredQuestions}/{numQuestions} complete</p>
          </div>
        )}
        {(started && userQuestions) ? (
          userQuestions.map((question, questionIndex) => (
            (<Row
              id={'questionRow' + questionIndex}
              style={{
                width: '100%',
                margin: '20px',
              }}
            >
              <Animated
                animationIn={
                  questionIndex % 2 == 0 ? 'slideInLeft' : 'slideInRight'
                }
                animationOut="fadeOut"
                animationInDelay={0}
                isVisible={true}
              >
                <div
                  style={{
                    flex: 2,
                    border: '1px solid black',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    padding: '5px',
                    width: '100%',
                  }}
                >
                  <div>
                    <h4>
                      {questionIndex + 1}. {question.question}
                    </h4>
                  </div>
                  <Row
                    style={{
                      width: '100%',
                      margin: '15px',
                    }}
                  >
                    {question.answers.map((answer, answerIndex) => (
                      <div
                        className={
                          answers[questionIndex] == answerIndex
                            ? ' quiz__answer__selected'
                            : 'quiz__answer'
                        }
                        onClick={() => updateAnswer(questionIndex, answerIndex)}
                      >
                        {answer}
                      </div>
                    ))}
                  </Row>
                </div>
              </Animated>
            </Row>)
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: 25, width: '100%' }}>
            {
              !quizEnded ?
                <button style={{ color: 'black' }
                } onClick={startQuiz}>
                  Start Quiz
                </button>
                :
                <p>Quiz has ended.</p>}
          </div>)}
        <Row>
          {allQuestionAnswered && !quizSubmitted && (
            <div className="quiz__submit__div" id="submitDiv">
              <button className="quiz__submit__btn" onClick={submitQuiz}>
                Submit Answers
              </button>
            </div>
          )}
        </Row>
      </Container>
    </div >
  )

  function showQuestion(questionIndex) {
    return questionIndex == 0
      ? true
      : answers[questionIndex - 1] != undefined
  }
}

export default QuizView
