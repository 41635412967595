import React, { useState, useEffect, useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export default function NFTFlipCard({
  animationDelay = 0,
  logoSrc,
  tokenUri,
  imageLoadDelay = 0,
}) {
  const [image, setImage] = useState('/assets/UnknownClown.png')
  const inter = useRef(0)

  const checkImageIsLoaded = () => {
    inter.current = setInterval(() => {
      let tokenId = getPropValue(tokenUri, 'edition')
      let im = document.getElementById('im' + tokenId)
      try {
        console.log('im.width...', im.width, tokenId)

        if (im.width < 100) {
          console.log('Reloading...', im.width)
          setImage(`${tokenUri.image}?${new Date().getTime()}`)

          //TEST
          // setImage(
          //   `https://bytemasonsviewer.mypinata.cloud/ipfs/QmRHb3xGTujNeaMWxj2u7tFH2Cuuf4MWJVoRGxFik6YQYJ/images/${tokenId}.png`,
          // )
        } else {
          clearInterval(inter.current)
          console.log('success...', im.width)
        }
      } catch (e) {
        console.log(e)
      }
    }, 3000)
  }

  useEffect(() => {
    if (!tokenUri || tokenUri == undefined) {
      setImage('/assets/UnknownClown.png')
    } else {
      setImage(tokenUri.image || '/assets/UnknownClown.png')
     
      //TEST
      // let tokenId = getPropValue(tokenUri, 'edition')
      // setImage(
      //   `https://bytemasonsviewer.mypinata.cloud/ipfs/QmRHb3xGTujNeaMWxj2u7tFH2Cuuf4MWJVoRGxFik6YQYJ/images/${tokenId}.png`,
      // )
    }

    // setImage('/assets/UnknownClown.png')
    // setTimeout(() => {
    //   if (!tokenUri) setImage('/assets/UnknownClown.png')
    //   else setImage(tokenUri.image || '/assets/UnknownClown.png')
    // }, imageLoadDelay)
  }, [tokenUri])

  const getPropValue = (obj, propName) => {
    if (!obj || obj == undefined) {
      return '?'
    }
    if (!obj[propName]) {
      return '?'
    }
    return obj[propName]
  }

  let tokenId = getPropValue(tokenUri, 'edition')
  let description = getPropValue(tokenUri, 'bio')
  let name = getPropValue(tokenUri, 'name')
  let tableProps = {
    Age: getPropValue(tokenUri, 'age'),
    'Net Worth': getPropValue(tokenUri, 'networth'),
    'C.O.D': getPropValue(tokenUri, 'causeofdeath'),
  }
  if (!tokenUri || tokenUri == undefined) {
    return null
  }
  return (
    <div className="float" style={{ animationDelay: `${animationDelay}s` }}>
      <div className="flip-container">
        <div className="flipper">
          <div className="front clownCardFront floatShadow">
            <h5
              style={{
                alignItems: 'center',
                verticalAlign: 'middle',
                height: '55px',
                fontSize: '16px',
                overflowY: 'hidden',
              }}
            >
              {name}
            </h5>
            <LazyLoadComponent afterLoad={checkImageIsLoaded}>
              <img
                id={'im' + tokenId}
                style={{
                  marginBottom: 10,
                }}
                height={'250px'}
                src={image}
                // afterLoad={checkImageIsLoaded}
              />
            </LazyLoadComponent>

            <table style={{ width: '280px' }}>
              <tbody>
                {Object.keys(tableProps).map((key) => {
                  return (
                    <tr key={key}>
                      <td>{key}:</td>
                      <td>{tableProps[key]}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <p
              style={{
                position: 'absolute',
                bottom: 0,
                left: 10,
                fontSize: 12,
              }}
            >
              ID: {tokenId}
            </p>
            {/* <p
              style={{
                position: 'absolute',
                bottom: 0,
                right: 10,
                fontSize: 12,
              }}
            >
              R: {rarity}
            </p> */}
          </div>
          <div className="back clownCardFront">
            <h5>{name}</h5>
            <p>Bio:</p>
            <p>{description}</p>
            <div style={{ backgroundColor: 'black' }}>
              <img
                style={{
                  position: 'absolute',
                  left: '50%',
                  bottom: '20px',
                  maxHeight: '150px',
                  height: '10%',
                  transform: 'translate(-50%,0)',
                }}
                src={logoSrc}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
