import React, { useState, useEffect } from 'react'
import AnimatedButton from '../AnimatedButton/AnimatedButton'
import { useRecoilState } from 'recoil'
import {
  cryptoFunctionsAtom,
  userTermsAtoms,
  oathAllowedSpendAtom,
  ftmAllowedSpendAtom,
  contractPausedAtom,
  nftTotalSupplyAtom,
  walletAddressAtom,
  nftMaxSupplyAtom,
  selectedDiscountNftAtom,
} from '../../recoil/atoms'
import NFTModal from '../NFTModal/NFTModal'
import { oathAddress, ftmAddress, nftDiscounts, sounds } from '../../general/variables'
import './MintPanel.css'
import useSound from 'use-sound'

const ZERO = '0x0000000000000000000000000000000000000000'

export default function MintPanel() {
  const [cryptoFunctions] = useRecoilState(cryptoFunctionsAtom)
  const [ftmAllowedSpend] = useRecoilState(ftmAllowedSpendAtom)
  const [oathAllowedSpend] = useRecoilState(oathAllowedSpendAtom)
  const [contractPaused] = useRecoilState(contractPausedAtom)
  const [walletAddress] = useRecoilState(walletAddressAtom)
  const [totalSupply] = useRecoilState(nftTotalSupplyAtom)
  const [maxSupply] = useRecoilState(nftMaxSupplyAtom)
  const [userTerms] = useRecoilState(userTermsAtoms)
  const [selectedDiscountNft, setSelectedDiscountNft] = useRecoilState(
    selectedDiscountNftAtom,
  )
  const [amount, setAmount] = useState(1)

  const [ftmPrice] = useState(120) //120
  const [oathPrice] = useState(420) //420
  const [playClownHonk, { stop }] = useSound(sounds.clownHonk, {
    volume: 0.7,
    interrupt: true,
  }) // 70% of the original volume

  function getFtmButton() {
    if (ftmAllowedSpend > 0) {
      return (
        <AnimatedButton
          imClicked="/assets/button-mintFTM-clicked.png"
          imNotClicked="/assets/button-mintFTM.png"
          className="animatedButton"
          alt="Mint FTM"
          onClick={() => {
            cryptoFunctions.mint(ftmAddress, amount, ftmPrice, selectedDiscountNft)
            playClownHonk()
          }}
        />
      )
    } else {
      return (
        <AnimatedButton
          imClicked="/assets/button-approveftm-clicked.png"
          imNotClicked="/assets/button-approveftm.png"
          className="animatedButton"
          alt="Approve FTM"
          onClick={() => cryptoFunctions.approveFtmSpend(walletAddress)}
        />
      )
    }
  }

  function getOathButton() {
    if (oathAllowedSpend > 0) {
      return (
        <AnimatedButton
          className="animatedButton"
          imClicked="/assets/button-mintOATH-clicked.png"
          imNotClicked="/assets/button-mintOATH.png"
          alt="Mint OATH"
          onClick={() => {
            cryptoFunctions.mint(oathAddress, amount, null, selectedDiscountNft)
            playClownHonk()
          }}
        />
      )
    } else {
      return (
        <AnimatedButton
          className="animatedButton"
          imClicked="/assets/button-approveOATH-clicked.png"
          imNotClicked="/assets/button-approveOATH.png"
          alt="Approve OATH"
          onClick={() => cryptoFunctions.approveOathSpend(walletAddress)}
        />
      )
    }
  }

  function getDiscount() {
    let lgeDiscount = 0
    let nftDiscount = 0
    let priceMultiplier = 1
    let discount = 0
    let discountSourceStr = 'NFT'
    if (userTerms && userTerms.termSec && userTerms.termSec > 0)
      lgeDiscount = Math.min(
        (35 + Math.sqrt(userTerms.termSec) / 800) / 100,
        0.5,
      )

    nftDiscount = selectedDiscountNft ? selectedDiscountNft.discount : 0

    if (nftDiscount > lgeDiscount) {
      discount = nftDiscount
      discountSourceStr = 'NFT'
    } else {
      discount = lgeDiscount
      discountSourceStr = 'LGE'
    }

    priceMultiplier = 1 - discount

    return { priceMultiplier, discount, discountSourceStr }
  }

  function getDiscountString(discount, discountSourceStr) {
    let percent = discount * 100
    if (discount > 0)
      return (
        <span className="mintpanel__discount">{`-${percent.toFixed(
          1,
        )}% ${discountSourceStr}`}</span>
      )
    return null
  }

  function increaseAmount() {
    setAmount(Math.min(amount + 1, 5))
  }

  function decreaseAmount() {
    setAmount(Math.max(amount - 1, 1))
  }

  let { priceMultiplier, discount, discountSourceStr } = getDiscount()
  let ftmPriceString = `${Math.round(ftmPrice * priceMultiplier)} WFTM `
  let oathPriceString = `${Math.round(oathPrice * priceMultiplier)} OATH`
  let supplyString = `${totalSupply}/${maxSupply}`
  let discountSpan = getDiscountString(discount, discountSourceStr)
  let oathButton = getOathButton()
  let ftmButton = getFtmButton()
  return (
    <div
      className="mintpanel"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="mintpanel__prices">
        <button className="spinner__button" onClick={decreaseAmount}>
          -
        </button>
        <div>Mint Amount {amount}/5</div>
        <button className="spinner__button" onClick={increaseAmount}>
          +
        </button>
      </div>
      <div className="mintpanel__prices">
        <div>
          {ftmPriceString}
          {discountSpan}
        </div>
        <div>{supplyString}</div>
        <div>
          {oathPriceString}
          {discountSpan}
        </div>
      </div>
      <div>
        {contractPaused ? (
          <p>Minting soon!</p>
        ) : (
          <>
            {ftmButton}
            {oathButton}
          </>
        )}
      </div>
      <NFTModal />
    </div>
  )
}
