import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { ethers } from 'ethers'
import { trackPromise } from 'react-promise-tracker'

import {
  lgeAddress as LGEAddress,
  oathAddress,
  nftAddress,
  ftmAddress,
  lgeAbi,
  wFtmAbi,
  nftDiscounts,
  nftAbi,
} from '../general/variables'

import { userTokenJsonsAtom, allMintedTokenJsonsAtom } from '../recoil/atoms'

const PAIN =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
const ZERO = '0x0000000000000000000000000000000000000000'
let LGE
let ERC20
var nftContract
let signer
var provider

function initializeEthers() {
  if (!window.ethereum) {
    alert(
      'No wallet found. You will not be able to use this site without one. Please ensure your browser has the Metamask extension installed or try using the Metamask mobile app browser.',
    )
    throw new Error('No crypto wallet found')
  }
  provider = new ethers.providers.Web3Provider(window.ethereum)
  signer = provider.getSigner(0)
  nftContract = new ethers.Contract(nftAddress, nftAbi, signer)
  LGE = new ethers.Contract(LGEAddress, lgeAbi, signer)
  ERC20 = new ethers.Contract(oathAddress, wFtmAbi, signer)
}
initializeEthers()

export function useTotalSupply() {
  const [totalSupply, setTotalSupply] = useState(0)

  async function getMaxSupply() {
    try {
      let totalSupply = await nftContract.totalSupply()
      totalSupply = await totalSupply.toNumber()
      setTotalSupply(totalSupply)
    } catch (error) {
      console.error('useMaxSupply error: ' + error.message)
      setTotalSupply(0)
    }
  }

  useEffect(() => {
    if (window.totalSuppplyInt) clearInterval(window.totalSuppplyInt)
    window.totalSuppplyInt = setInterval(getMaxSupply, 5000)
  }, [])

  return totalSupply
}

export function useMaxSupply() {
  const [maxSupply, setMaxSupply] = useState(0)
  useEffect(async () => {
    try {
      let maxSupply = await nftContract.maxSupply()
      maxSupply = await maxSupply.toNumber()
      setMaxSupply(maxSupply)
    } catch (error) {
      console.error('useMaxSupply error: ' + error.message)
      setMaxSupply(0)
    }
  }, [])
  return maxSupply
}

export async function getAllWalletTokenIds(walletAddress) {
  let promises = []
  let userNftBalance = await nftContract.balanceOf(walletAddress)
  userNftBalance = userNftBalance.toNumber()

  for (let index = 0; index < userNftBalance; index++) {
    promises.push(
      nftContract
        .tokenOfOwnerByIndex(walletAddress, index)
        .then((tokenId) => tokenId.toNumber())
        .catch((e) => {
          console.log(e)
        }),
    )
  }
  return await Promise.all(promises)
}

export function useUserTokenIds() {
  const [userTokenIds, setUserTokenIds] = useState([])

  useEffect(async () => {
    const addressArray = await provider.send('eth_requestAccounts', [])
    let walletAddress = addressArray[0]
    let promises = []
    let userNftBalance = await nftContract.balanceOf(walletAddress)
    userNftBalance = userNftBalance.toNumber()

    for (let index = 0; index < userNftBalance; index++) {
      promises.push(
        nftContract
          .tokenOfOwnerByIndex(walletAddress, index)
          .then((tokenId) => tokenId.toNumber())
          .catch((e) => {
            console.log(e)
          }),
      )
    }

    Promise.all(promises)
      .then((idArray) => {
        setUserTokenIds(idArray)
      })
      .catch((e) => {
        console.log(' Promise.all error:', e)
      })
  }, [])

  return userTokenIds
}

export function useUserNFTs() {
  const mintedNFTs = useMintedNFTs()
  const [userNFTs, setUserNFTs] = useRecoilState(userTokenJsonsAtom)

  useEffect(async () => {
    const addressArray = await provider.send('eth_requestAccounts', [])
    let walletAddress = addressArray[0]
    let promises = []
    let userNftBalance = await nftContract.balanceOf(walletAddress)
    userNftBalance = userNftBalance.toNumber()

    for (let index = 0; index < userNftBalance; index++) {
      promises.push(
        nftContract
          .tokenOfOwnerByIndex(walletAddress, index)
          .then((tokenId) => tokenId.toNumber())
          .catch((e) => {
            console.log(e)
          }),
      )
    }

    Promise.all(promises)
      .then((idArray) => {
        if (mintedNFTs.length > 0) {
          console.log('mintedNFTs', mintedNFTs)
          let fitlered = mintedNFTs.filter((nft) => {
            if (nft) return idArray.includes(nft.edition)
            else return false
          })
          setUserNFTs(fitlered)
        }
      })
      .catch((e) => {
        console.log(' Promise.all error:', e)
      })
  }, [mintedNFTs])

  return userNFTs
}

export function useMintedNFTs() {
  const [mintedNFTs, setMintedNFTs] = useRecoilState(allMintedTokenJsonsAtom)

  useEffect(async () => {
    initializeEthers()
    console.log(nftAddress, LGEAddress, oathAddress, signer)
    let totalSupply = await nftContract.totalSupply()
    let promises = []
    for (let index = 0; index < totalSupply; index++) {
      promises.push(
        nftContract
          .tokenByIndex(index)
          .then(async (tokenId) => {
            tokenId = await tokenId.toNumber()
            let data = {}
            try {
              data = await nftContract
                .tokenURI(tokenId)
                .then((tokenUri) => fetch(tokenUri))
                .then((data) => data.json())
                .then((jsonData) => {
                  return jsonData
                })
                .catch((e) => {
                  console.log(e)
                })
            } catch (e) {
              console.log(e)
            }
            return data
          })
          .catch((e) => {
            console.error(e)
          }),
      )
    }
    Promise.all(promises)
      .then((arrArr) => {
        setMintedNFTs(arrArr)
      })
      .catch((e) => {
        console.log(' Promise.all error:', e)
      })
  }, [])

  return mintedNFTs
}
