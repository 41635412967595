/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { clowns } from '../../general/variables'
import TypeWriter from '../../components/TypeWriter/TypeWriter'
import { useCookies } from 'react-cookie'

function LoreView({}) {
  const [cookies, setCookies] = useCookies(['nftSiteSettings'])
  let clown = clowns[1]
  let delay = 5000
  if (cookies.introPlayed) {
    if (cookies.introPlayed == 'true') {
      delay = 0
    } else {
      delay = 5000
    }
  }

  return (
    <div className="typeWriteDiv">
      <p>
        <TypeWriter
          speed={30}
          delay={delay+250}
          content={`
The Cursed Circus

Clowns. Funny Fellas. Wacky Women. They come in all shapes and sizes. 

They are all around us. Wherever people gather, there are clowns. They may not even be aware of their true nature. Your nose may not be red but that may very well be a clown looking back at you in the mirror.  

What makes a person a clown? Is it birth? Is it choice? We don’t know. 

But, we do know one thing. 

When clowns die, they go someplace else. 

Not quite Hell. Definitely not Heaven. 

Upon laughing their last, each and every clown goes to the same place. Evil, Nice, Funny, Sad -- irrelevant. 

Their final forever home is The Cursed Circus. 

Here the clown sheds the false flesh of humanity and frees their funny form. Their true nature becomes unbound. While they are bound forever to the Benighted Big Top of the Void they are free to be clowns! They can be silly and funny, twist balloons into imperceivable forms or just chuff a fat dart whenever they want! 

So long as they’ve got the cash…

Where does the money come from? That’s above your pay grade chum. Just know one thing: 

- no laugh is free -`}
        />
      </p>
    </div>
  )
}

export default LoreView
