import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import AnimatedButton from '../AnimatedButton/AnimatedButton'

import {
  availableDiscountNftsAtom,
  selectedDiscountNftAtom,
} from '../../recoil/atoms'
import { Container, Row, Col, Modal, Header, Button } from 'react-bootstrap'
import './NFTModal.css'

function NftGrid({ availableDiscountNfts, setSelectedDiscountNft }) {
  if (!availableDiscountNfts || availableDiscountNfts.length == 0)
    return (
      <p>
        Looks like you don't own any of the supported nfts. Check out the
        partners page to buy some!
      </p>
    )

  return (
    <Container
      fluid
      className="GalleryContainer"
      style={{
        height: '100%',
        width: '100%',
        maxHeight: '500px',
        overflowY: 'scroll',
        padding: '10px',
      }}
    >
      <Row xs={2} md={4} lg={6}>
        {' '}
        {availableDiscountNfts.map((nft) => (
          <Col>
            <div
              className="nftbutton"
              onClick={() => setSelectedDiscountNft(nft)}
            >
              <img height="60px" src={nft.image} />
              <p
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  bottom: '-20px',
                  left: '0px',
                  color: 'red',
                  width: '100%',
                  backgroundColor: 'rgba(0,0,0,0.6)',
                }}
              >
                -{nft.discount * 100}%
              </p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

function SelectedNFTTable({ setSelectedDiscountNft, selectedDiscountNft }) {
  if (!selectedDiscountNft)
    return <p style={{ color: 'red' }}>No NFT selected.</p>
  return (
    <table style={{ width: '100%' }}>
      <tr>
        <td style={{ color: 'green' }}>Applied Discount: </td>
        <td style={{ textAlign: 'right' }}>{selectedDiscountNft.name}</td>
        <td>
          <div
            className="nftbutton"
            onClick={() => setSelectedDiscountNft(null)}
          >
            <img height="60px" src={selectedDiscountNft.image} />
            <p className="nftbutton__discount">
              -{selectedDiscountNft.discount * 100}%
            </p>
          </div>{' '}
        </td>
      </tr>
    </table>
  )
}

export default function NFTModal() {
  const [show, setShow] = useState(false)
  const [availableDiscountNfts] = useRecoilState(availableDiscountNftsAtom)
  const [selectedDiscountNft, setSelectedDiscountNft] = useRecoilState(
    selectedDiscountNftAtom,
  )

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <AnimatedButton
        className="animatedDiscountButton"
        style={{ width: '100%' }}
        imClicked="/assets/button-NFTDiscounts-clicked.png"
        imNotClicked="/assets/button-NFTDiscounts.png"
        alt="NFT Discounts Button"
        onClick={handleShow}
      />
      <Modal scrollable={true} show={show} onHide={handleClose}>
        <Modal.Header className="modal__header" closeButton>
          <Modal.Title className="modal__title">Your Discounts</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal__body">
          <NftGrid
            availableDiscountNfts={availableDiscountNfts}
            setSelectedDiscountNft={setSelectedDiscountNft}
          />
        </Modal.Body>
        <Modal.Footer className="modal__footer">
          <SelectedNFTTable
            setSelectedDiscountNft={setSelectedDiscountNft}
            selectedDiscountNft={selectedDiscountNft}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
