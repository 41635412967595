import { useEffect, useState, useRef } from 'react'
import { useUserTokenIds } from '../../hooks/Web3Hooks'
import { useRecoilState } from 'recoil'
import { startDate, endDate } from '../../general/variables'

import {
    walletAddressAtom,
} from '../../recoil/atoms'
import './QuizView.css'

const localTime = startDate.toLocaleString({
    dateStyle: 'short',
    timeStyle: 'short',
})
const localTimeEnd = endDate.toLocaleString({
    dateStyle: 'short',
    timeStyle: 'short',
})
export default function QuizCountDown({ children }) {
    const [walletAddress] = useRecoilState(walletAddressAtom)
    const userTokenIds = useUserTokenIds(walletAddress)


    const interval = useRef(0)
    const [showQuiz, setShowQuiz] = useState(false)
    const [secondsToStart, setSecondsToStart] = useState(0)
    const [secondsToEnd, setSecondsToEnd] = useState(0)

    function ddhhmmss(seconds) {
        var days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        var hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        var mnts = Math.floor(seconds / 60);
        seconds -= mnts * 60;
        return days + "d " + hrs + "h " + mnts + "m " + seconds.toFixed(0) + "s"
    }
    function checkTime() {
        let now = new Date();
        var secondsStart = (startDate.getTime() - now.getTime()) / 1000;
        var secondsEnd = (endDate.getTime() - now.getTime()) / 1000;
        setSecondsToStart(ddhhmmss(secondsStart))
        if (secondsEnd > 0)
            setSecondsToEnd("Ending in: " + ddhhmmss(secondsEnd))
        else setSecondsToEnd("Quiz over!")
        if (secondsStart <= 0) {
            setShowQuiz(true)
            return
        }
        setSecondsToStart(ddhhmmss(secondsStart))
    }

    useEffect(() => {
        if (interval.current == 0)
            interval.current = setInterval(checkTime, 500)
        checkTime();
    }, [])

    if (showQuiz) return (
        <>
            <div style={{
                padding: 5,
                textAlign: 'center'
            }}>{secondsToEnd}</div>
            {children}
        </>
    )
    return (
        <div className="quiz__intro" >
            <div style={{
                padding: 25,
                margin: 25,
                backgroundColor: '#000000b5',
                border: '1px solid red',
                textAlign: 'center'
            }}>

                <h6>Quiz countdown: </h6>
                <h6><font style={{ color: 'green' }}>{secondsToStart}</font></h6>
                <h6>Starting (Local 24h time): </h6>
                <h6><font style={{ color: 'green' }}>{localTime}</font></h6>

                <h6>Ending (Local 24h time): </h6>
                <h6><font style={{ color: 'red' }}>{localTimeEnd}</font></h6>

                <br />

                <p>Have you studied yet?</p>
                <p>The quiz will test your DeFi knowledge!</p>
                <p>Make sure to read up at <a href="https://lrn.fi/">lrn.fi</a></p>
                <p style={{ color: 'red' }}>You must have at least 1 Cursed Circus NFT in your connected wallet to participate.</p>
                <p>Your current wallet: {walletAddress}</p>
                <p>Cursed Circus NFT's: {userTokenIds.length}</p>
                {userTokenIds.length > 0 ? <p style={{ color: 'green' }}>This wallet can participate!</p> : <p style={{ color: 'red' }}>This wallet does not have a Cursed Circus NFT. You cannot do the quiz.</p>}
            </div>
        </div>


    )

}