import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import {
  correctNetworkAtom,
  walletAddressAtom,
  cryptoFunctionsAtom,
} from '../../recoil/atoms'
import { useRecoilState } from 'recoil'
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton'
import { Animated } from 'react-animated-css'

export default function CheckNetwork({ children }) {
  const [correctNetwork] = useRecoilState(correctNetworkAtom)
  const [crypto] = useRecoilState(cryptoFunctionsAtom)
  const [walletAddress] = useRecoilState(walletAddressAtom)
  const [cookies, setCookies] = useCookies(['nftSiteSettings'])

  const [domClicked, setDomClicked] = useState(false)
  const [animationEnded, setAnimationEnded] = useState(false)

  useEffect(() => {
    if (cookies.playSound) {
      if (cookies.playSound == 'true') {
        setDomClicked(false)
        setAnimationEnded(false)
      } else {
        setDomClicked(true)
        setAnimationEnded(true)
      }
    }
  }, [])

  function connectToFantom(e) {
    e.preventDefault()
    crypto.connectToFantomNetwork()
    setDomClicked(true)
    setTimeout(() => setAnimationEnded(true), 1500)
  }

  function connectWallet(e) {
    e.preventDefault()
    crypto.connectWallet()
    setDomClicked(true)
    setTimeout(() => setAnimationEnded(true), 1500)
  }

  function enterClicked() {
    setDomClicked(true)
    setTimeout(() => setAnimationEnded(true), 1500)
  }

  if (correctNetwork && walletAddress && domClicked && animationEnded)
    return children
  else
    return (
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: 20,
          overflowY: 'hidden',
          color: 'white',
          backgroundColor: 'black',
        }}
      >
        <Animated animationOut="fadeOut" isVisible={!domClicked}>
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              textAlign: 'center',
            }}
          >
            <Animated
              animationIn="fadeInDown"
              animationOut="fadeOut"
              animationInDelay={200}
              isVisible={true}
            >
              <img
                alt={'Clown Logo'}
                style={{ height: '400px' }}
                src={'/assets/CursedCircus-promo1-trans.png'}
              />
            </Animated>
            {correctNetwork && walletAddress ? (
              <button
                onClick={enterClicked}
                style={{ color: 'black', marginTop: '25px' }}
              >
                Enter
              </button>
            ) : (
              <>
                <Animated
                  animationIn="fadeInLeft"
                  animationOut="fadeOut"
                  animationInDelay={600}
                  isVisible={true}
                >
                  <h6>
                    Please{' '}
                    <a className="connectNetworkText" onClick={connectToFantom}>
                      [connect]
                    </a>{' '}
                    to the Fantom Network to use this website.
                  </h6>
                </Animated>
                <Animated
                  animationIn="fadeInUp"
                  animationOut="fadeOut"
                  animationInDelay={1000}
                  isVisible={true}
                >
                  <AnimatedButton
                    className="animatedButton"
                    imClicked="/assets/button-connectwallet-clicked.png"
                    imNotClicked="/assets/button-connectwallet.png"
                    alt="Conenect Wallet"
                    onClick={connectWallet}
                  />
                </Animated>
              </>
            )}
          </div>
        </Animated>
      </div>
    )
}
